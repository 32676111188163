import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const AllBlogs = () => {
  const [blogs,setBlogs]=useState()
  const [loader,setLoader]=useState(false)
  useEffect(() => {
  
    
   const getblogFun=async()=>{
    setLoader(true)
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallblog`)
    .then((res)=>{
       
       const data=res?.data
       setBlogs(data)
       setLoader(false)
    }).catch((error)=>{
       console.log("API not Working"+error)
    })
   }
   getblogFun()
 }, [])

  return (
<>
    <p className='bg-teal-700 text-center p-2 text-md font-bold text-gray-300'>Blogs Section</p>
    <p className='text-blue-500 text-md p-2 text-center font-semibold'>{loader?"Loading...":""}</p>
    <div className="grid grid-cols-1 text-center gap-2 m-1 md:grid-cols-4">
   
  {
    blogs?.map((res,i)=>( 
      <Link to={`/blogdetails/${res._id}`} key={i} className="bg-white shadow-md rounded-sm cursor-pointer my-1 ">
      <img src={res?.postimg} alt="img" className="hover:opacity-80 h-60 md:h-32 w-full" />
      <div className="my-3">
      <div className='flex justify-between items-center pb-2 bg-gray-100'>
        <p className='bg-gray-100 text-blue-700 ml-1 p-1 font-bold text-md text-center'>{res?.title}</p>
        {/* <div className='flex justify-between items-center'>
        <p className='mx-1 text-xs'>posted by <i className='text-teal-600 font-semibold'>{res?.username}</i></p>
        <img src={res?.userprofile} alt="img" className='h-10 w-10 rounded-full mt-2 mr-2' />
        </div> */}
     </div>
      <p className='text-sm ml-1 text-start font-semibold text-gray-600'>{res?.disc?.length>100?`${res?.disc?.substring(0,100)}....`:res?.disc}</p>
  
      </div>
    </Link>
    ))
  }
     </div>
     </>
  )

}

export default AllBlogs