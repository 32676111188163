
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const ITEMS_PER_PAGE = 6; // Adjust the number of items per page as needed

const AdminAllDonation = () => {
    const [donationData, setDonationData] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchDonations = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/show-donation`);
               
                setDonationData(response.data);


                const totalDonatedAmount = response?.data?.data?.reduce((accumulator, res) => {
                    return accumulator + (res?.donatedAmount || 0);
                  }, 0);
                  setTotalAmount(totalDonatedAmount)
                  
            } catch (err) {
                console.error("Error fetching donations:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchDonations();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading donations: {error.message}</div>;

    // Calculate the indices for slicing the donation data
    const totalItems = donationData?.data.length || 0;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Sort donations by date (assuming a date field exists)
    const sortedDonations = donationData?.data.sort((a, b) => new Date(b.date) - new Date(a.date));
    const currentDonations = sortedDonations?.slice(startIndex, endIndex);

    return (
        <>
            <div className='text-center bg-teal-500 p-2'>
                All Donations ({totalItems})
                <p className='mt-1 text-gray-700 font-semibold'>Total Amount :- ₹{totalAmount}</p>
            </div>
            <div className='flex flex-wrap justify-center'>
                {currentDonations?.map((donation, index) => (
                    <div key={index} className='w-full px-4 shadow-lg m-2 lg:w-[30rem]'>
                        <div className='flex justify-between items-center'>
                            <img
                                src={donation?.donarProfile || "./img/icons/profile.webp"}
                                alt="Donor Profile"
                                className='w-16 h-16 rounded-full p-2'
                            />
                            <p>{donation?.donarName}</p>
                            <p>₹{donation?.donatedAmount}</p>
                        </div>
                        <p className='text-center mb-1 text-[10px]'>Date :- {donation?.date}</p>
                    </div>
                ))}
            </div>
            <div className='flex justify-center mt-4'>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentPage(index + 1)}
                        className={`mx-1 px-4 py-2 rounded ${currentPage === index + 1 ? 'bg-teal-600 text-white' : 'bg-white text-teal-600'}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </>
    );
};

export default AdminAllDonation;

