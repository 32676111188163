

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import dateFormat from 'dateformat';

const ChildEducationDetails = () => {
    const [eventDetail,setEventDetail]=useState()
    const {id}=useParams()
    useEffect(()=>{
     const DetailFun=async()=>{
        await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallevent`)
        .then((res)=>{
            const data=res.data
            const eventDetail=data?.filter(item => item._id==id)
            setEventDetail(eventDetail)
        }).catch((error)=>{
            console.log("something went wrong"+error)
        })
     }
     DetailFun()
    },[])
  
  return (
    <div>
       {eventDetail?.map((res,i)=>(
    <div className='lg:flex-col justify-between items-start' key={i}> 
     
   <div className='mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
    {res.postimg?.map((img)=>(
      
          <img src={img} alt="img" className='p-1 h-32 md:h-80 w-full' />
         

    ))}
      
   </div>
   <div className='lg:mt-2 shadow-md'>
   <div className='flex-col md:flex justify-between items-center pb-2 bg-gray-100'>
        <p className='bg-gray-100 ml-10 p-2 font-bold text-lg text-center'>{res?.title}</p>
        <div className='flex justify-between items-center'>
        <p className='mx-1 text-xs'>Created by <i className='text-teal-600 font-semibold'>{res.username}</i></p>    
        <img src={res.userprofile} alt="img" className='h-10 w-10 rounded-full mt-2 mr-2' />
        </div>
     </div>
     <div className='flex-col md:flex justify-between items-center pb-2 bg-gray-100 p-4'>
               <p className='my-2 font-semibold text-teal-700'><b>Date:- </b>{dateFormat(res.eventdate,"dddd, mmmm dS, yyyy")}  <b>Time:- </b>{res.eventtime}</p>
               <p className='my-2 font-semibold  text-blue-800'><b>Location:-</b> {res.location}</p>
               {res.status? <Link to={`/payment1/${res?._id}`} className="bg-red-500 font-semibold cursor-pointer p-1 px-2 rounded-sm text-white mt-3 hover:bg-red-600">Donate Now</Link>:<p className='my-2 font-semibold text-lg  text-red-800'><b>This Event have been complited!</b></p>}
     </div>
       <p className='p-3 text-md font-semibold'>{res?.disc}</p>
   
   </div></div>
       ))}
        
    </div>
  )
}

export default ChildEducationDetails