import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'

const Allpost = () => {
    const Navigate=useNavigate()
const [blogs,setBlogs]=useState()
const [loader,setLoader]=useState(false)
const id = localStorage.getItem('id');


const getblogFun=async()=>{
  setLoader(true)
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallblog`)
    .then((res)=>{
       const data=res.data
       setBlogs(data)
       setLoader(false)
    }).catch((error)=>{
       console.log("API not Working"+error)
       setLoader(false)
    })
   }

    useEffect(() => {
        const isToken = localStorage.getItem('token');
         if(!isToken){
            Navigate("/login")
         }
    
       getblogFun()
     }, [])
   const editPost=(_id)=>{

   }
   const deletePost=async(id)=>{

    console.log(id)
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/deletepost/${id}`)
    .then((res)=>{
        console.log("Delete Success")
        getblogFun()
    }).catch((error)=>{
       console.log("API not Working"+error)
    })
   }

  return (
    <>
    {loader? <span className='font-bold text-center ml-4 text-md text-blue-500 p-2'>Loading...</span>:""}
   
    <div className="grid grid-cols-1 text-center gap-2 m-1 md:grid-cols-3">
    {
     blogs?.map((res,i)=>(
 
         <>
         <Link to="" className="shadow-md rounded-sm my-1 ">
         <img src={res.postimg} alt="img" className="hover:opacity-80 h-80 w-full" />
         <div className="my-3">
         <p className="my-2 font-bold text-gray-600 text-lg">{res?.title?.length>30?`${res?.title.substring(0,30)}....`:res?.title}</p>
   
        <div className='flex justify-between items-center pb-2 bg-gray-100'>
        <div className='flex justify-between items-center'>
             <Link to={`/editBlog/${res?._id}`} className='ml-4 text-xs cursor-pointer text-blue-400 font-semibold' >Edit</Link>
             <span className='ml-6 text-xs cursor-pointer text-red-400 font-semibold' onClick={()=>(deletePost(res._id))}>Delete</span>
         </div>
           <div className='flex justify-between items-center'>
           <p className='mx-1 text-xs'>posted by <i className='text-teal-700 font-semibold'>{res.username}</i></p>
           <img src={res.userprofile} alt="img" className='h-10 w-10 rounded-full mx-2' />
           
           </div>
        </div>
         <p className='text-md'>{res?.disc?.length>150?`${res?.disc.substring(0,150)}....`:res?.disc}</p>
         </div>
       </Link>
       
       </>
     )
    )
    }
 
    
    
 
 
      </div>
      </> )
}

export default Allpost