import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const UpdateUserProfile = () => {
  const data = localStorage.getItem("userData");
  const userData = JSON.parse(data);
  var id = userData?.id;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setmessage] = useState("");
  const [status, setStatus] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState();
  const [gender, setGender] = useState("");
  const [profile, setProfile] = useState();
  const [facebook, setFacebook] = useState(null);
  const [youtube, setYoutube] = useState(null);
  const [x, setX] = useState(null);
  const [linkedin, setLinkedin] = useState(null);

  const UpdatedData = {
    name,
    mobile,
    dob,
    gender,
    profile,
    socialMedia: {
      facebook,
      youtube,
      x,
      linkedin,
    },
  };

  // --------------------------------------------------------------------------------
  useEffect(() => {
    GetSingleUser();
  }, []);

  const GetSingleUser = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/getsingleuser/${id}`)
        .then((res) => {
          console.log("single", res);

          setName(res?.data?.name);
          setEmail(res?.data?.email);
          setDob(res?.data?.dob);
          setMobile(res?.data?.mobile);
          setGender(res?.data?.gender);
          setProfile(res?.data?.profile);
          setFacebook(res?.data?.socialMedia?.facebook);
          setX(res?.data?.socialMedia?.x);
          setYoutube(res?.data?.socialMedia?.youtube);
          setLinkedin(res?.data?.socialMedia?.linkedin);
        });
    } catch (error) {
      console.log(error);
      setmessage(error.response.data.message);
      setStatus(error.response.status);
    }
  };
  // ---------------------------------upload profile--------------------------------------------------

  const onselectFile = async (e) => {
    const cloud_name = "dkmsgd3ua";
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "trust-image");
    setLoading(true);
    await axios
      .post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        formData
      )
      .then((res) => {
        const newimg = res?.data?.secure_url;
   
        setProfile(newimg);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // -----------------------------------------------------------------------------------------------

  const UserUpdateFun = async () => {
    try {
    
      await axios
        .put(`${process.env.REACT_APP_BASE_URL}/v1/updateuser/${id}`, {
          UpdatedData,
        })
        .then((res) => {
        

          setName("");
          setEmail("");
          setDob("");
          setGender("");
          setLoading(false);
          setStatus(res.status);
          setmessage(res.data.message);
          setTimeout(() => {
            navigate("/profile");
          }, 1000);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);

      setmessage(error.response.data.message);
      setStatus(error.response.status);
    }
  };
  // -----------------------------------------------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    UserUpdateFun();
  };
  return (
    <>
      <div className="w-full lg:w-1/2 m-auto mt-4">
        <div className="text-center font-bold text-lg text-teal-800">
          Update Profile
        </div>
        <div className="flex justify-center m-1">
          <img src={profile} alt="profile" className="h-48 w-40" />
        </div>
        <div className="flex justify-center m-1">
          <label htmlFor="photo" className="flex justify-center items-center">
            <p className="text-blue-500 font-semibold hover:text-blue-600 cursor-pointer">
              Change Profile
            </p>
          </label>
        </div>
        <div className="flex justify-center m-1 mt-4">
        <Link to="/change/password" className="text-blue-500 font-semibold hover:text-blue-600 cursor-pointer">Change Password</Link>
        </div>
        {loading ? (
          <p className="text-center text-gray-200 font-semibold bg-blue-500 p-2 my-2 rounded-sm">
            Click On Update After Loading...
          </p>
        ) : (
          ""
        )}

        <form
          onSubmit={handleSubmit}
          className={` bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 ${
            loading ? "opacity-30" : "opacity-100"
          }`}
        >
          <div className="lg:flex lg:justify-around">
            <div>
              <div className="mb-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  Full Name
                </label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  required
                  value={name}
                  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Full Name"
                />
              </div>

              <div className="mb-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="Email"
                >
                  Email
                </label>
                <input
                  // onChange={(e) => setEmail(e.target.value)}
                  required
                  value={email}
                  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="email"
                  placeholder="Valid Email"
                />
              </div>

              <div className="mb-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="Email"
                >
                  Mobile
                </label>
                <input
                  onChange={(e) => setMobile(e.target.value)}
                  required
                  value={mobile}
                  className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="number"
                  placeholder="Valid Mobile"
                />
              </div>
              <div className="mb-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="DOB"
                >
                  Date of Birth
                </label>
                <input
                  onChange={(e) => setDob(e.target.value)}
                  required
                  value={dob}
                  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="date"
                  placeholder="dd-mm-yy"
                />
              </div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="gender"
              >
                Gender
              </label>
              <div className="flex justify-between items-center">
                <div className="mb-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="male"
                  >
                    <input
                      onChange={(e) => setGender(e.target.value)}
                      value="male"
                      name="gender"
                      checked={gender === "male"}
                      className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline"
                      id="radio"
                      type="radio"
                      placeholder="male"
                    />
                    Male
                  </label>
                </div>
                <div className="mb-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="female"
                  >
                    <input
                      onChange={(e) => setGender(e.target.value)}
                      value="female"
                      name="gender"
                      checked={gender === "female"}
                      className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline"
                      id="radio"
                      type="radio"
                      placeholder="female"
                    />
                    Female
                  </label>
                </div>
                <div className="mb-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="others"
                  >
                    <input
                      onChange={(e) => setGender(e.target.value)}
                      value="others"
                      name="gender"
                      checked={gender === "others"}
                      className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline"
                      id="radio"
                      type="radio"
                      placeholder="other"
                    />
                    Other
                  </label>
                </div>
              </div>
            </div>
            <div>
              {status == 400 ? (
                <p
                  className={`text-center text-gray-200 font-semibold ${
                    status == 400 ? "bg-red-600" : ""
                  } p-2 my-2 rounded-sm`}
                >
                  {message}
                </p>
              ) : (
                ""
              )}
              {status == 200 ? (
                <p
                  className={`text-center text-gray-200 font-semibold ${
                    status == 200 ? "bg-green-700" : ""
                  } p-2 my-2 rounded-sm`}
                >
                  {message}
                </p>
              ) : (
                ""
              )}

              <div className="mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  Facebook Link
                </label>
                <input
                  onChange={(e) => setFacebook(e.target.value)}
                  value={facebook}
                  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-blue-500 leading-tight focus:outline-none focus:shadow-outline"
                  id="facebook"
                  type="text"
                  placeholder="Facebook profile link"
                />
              </div>
              <div className="mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  X Link
                </label>
                <input
                  onChange={(e) => setX(e.target.value)}
                  value={x}
                  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-blue-500 leading-tight focus:outline-none focus:shadow-outline"
                  id="X"
                  type="text"
                  placeholder="X profile link"
                />
              </div>
              <div className="mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  Youtube Link
                </label>
                <input
                  onChange={(e) => setYoutube(e.target.value)}
                  value={youtube}
                  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-blue-500 leading-tight focus:outline-none focus:shadow-outline"
                  id="youtube"
                  type="text"
                  placeholder="Youtube profile link"
                />
              </div>
              <div className="mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  Linkedin Link
                </label>
                <input
                  onChange={(e) => setLinkedin(e.target.value)}
                  value={linkedin}
                  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-blue-500 leading-tight focus:outline-none focus:shadow-outline"
                  id="linked"
                  type="text"
                  placeholder="Linked profile link"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-2">
            <button className="bg-red-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Update
            </button>
          </div>
        </form>
      </div>
      <form action="">
        <input
          className="mt-1 outline-blue-400 text-md font-semibold rounded-sm border-2 border-gray-200 hidden"
          type="file"
          name="file"
          id="photo"
          accept="image/png,image/jpeg,image/jpg,image/webp"
          onChange={(e) => onselectFile(e)}
        />
      </form>
    </>
  );
};

export default UpdateUserProfile;
