import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";


const UserProfile = () => {


  const Navigate = useNavigate();
  let data = localStorage.getItem('userData');
  let userData = JSON.parse(data);
  let id=userData?.id
  let name=userData?.name
  let token=userData?.token

  const getUserFun=async()=>{

       await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getsingleuser/${id}`)
       .then((res)=>{
   
        
       }).catch((error)=>{
        console.log(error)
       })
  }

  useEffect(() => {
    if (!token) {
      Navigate("/");
    }
    getUserFun()
  },[]);

  const logoutFun = () => {
    localStorage.clear();
    Navigate("/");
  };
   
  return (
    <>
    
    <div className="flex justify-between items-center px-2">
       <p className='text-gray-500 font-bold mr-4'>{"Hello " + name}</p>
        <button
          onClick={logoutFun}
          className="bg-red-600 text-white mx-2 p-1 px-2 text-xs font-semibold cursor-pointer rounded-sm focus:outline-none focus:shadow-outline"
        >
          Logout
        </button>
       </div>
      <div className="flex justify-around items-center m-1 p-1 text-xs md:text-md lg:text-lg font-semibold text-center md:mb-10">
    <> <Link
          to="/createblog"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/blog.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Create Blog</p>
        </Link>
         <Link
         to="/manageblog"
         className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
       >
         <img
           src="./img/icons/event.png"
           alt="img"
           className="h-14 md:h-32 lg:h-48"
         />
         <p className="p-1 bg-green-200 rounded-sm cursor-pointer hover:text-blue-400">
           Yore Blogs
         </p>
       </Link>

          
       <Link to="/update-user-profile" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
          <img
            src="./img/icons/program.jpeg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-blue-200 rounded-sm">Edit Profile</p>
        </Link>
   
       </>
       
        



   
      
       
        
      </div>
    </>
  );
};

export default UserProfile;
