import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ThankYou = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  // const [email,setEmail]=useState('')
  const [amount,setAmount]=useState('')
  // const [paymentId,setPaymentId]=useState('')
  // const [eventId,setEventId]=useState('')
  // const [donationDate,setDonationDate]=useState('')
  // const [panNumber,setPanNumber]=useState('')
  // const [address,setAddress]=useState('')

  useEffect(() => {
    getPaymentDetails();
  }, [id]);
  // const getPaymentDetails = async () => {
  //     await axios
  //       .get(`${process.env.REACT_APP_BASE_URL}/v1/show-donation`)
  //       .then((res) => {
  //         console.log("Donar Details thank you page",res)
  //         const donarData = res?.data?.data?.filter((item) => item?._id === id);
  //         console.log("donarData",donarData)
  //         donarData?.map((res,i)=>{
  //             setName(res?.donarName)
  //             setEmail(res?.donarEmail)
  //             setAmount(res?.donatedAmount)
  //             setPaymentId(res?.razorpay_payment_id)
  //             setEventId(res?.eventId)
  //             setDonationDate(res?.date)
  //             setPanNumber(res?.panNumber)
  //             setAddress(res?.address)

  //         })

  //       })
  //       await pdfFun()
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };
  // ------------------------------pdf-conert-fun----------------------------------

  const getPaymentDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/show-donation`
      );
      console.log("Donar Details thank you page", response);

      const donarData = await response?.data?.data?.filter(
        (item) => item?._id === id
      );
    
      const {
        donarName,
        donarEmail,
        donatedAmount,
        date,
        razorpay_payment_id,
        eventId,
        _id
      } = donarData[0];
      let userData = {
        name: donarName,
        email: donarEmail,
        amount: donatedAmount,
        donationDate: date,
        paymentId: razorpay_payment_id,
        eventId: eventId,
        id:_id
      };
      pdfFun(userData);

      if (donarData.length > 0) {
        const data = donarData[0]; // Assuming you only need the first matched item
        setName(data?.donarName);
        // setEmail(data?.donarEmail);
        setAmount(data?.donatedAmount);
        // setPaymentId(data?.razorpay_payment_id);
        // setEventId(data?.eventId);
        // setDonationDate(data?.date);
        // setPanNumber(data?.panNumber);
        // setAddress(data?.address);
      }
    } catch (error) {
      console.log("Error fetching payment details:", error);
    }
  };

  const pdfFun = async (userData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/pdf-converter`,
        { userData }
      );

     console.log("PDF Genrated",res)
      navigate("/");
    } catch (error) {
        console.log("pdf-converter",error)
    }
  };

  return (
    <>
      <div
        className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
        style={{ backgroundImage: "url('/img/icons/thanks.gif')" }}
      >
        <div
          className="relative max-w-md mx-auto p-6 bg-gradient-to-r from-gray-200 to-green-300 shadow-lg rounded-lg text-center z-10"
          style={{ backgroundImage: "url('./img/icons/thanks.gif')" }}
        >
          <h1 className="text-3xl font-bold text-green-600 mb-2">
            Thank's Your Kindness!
          </h1>
          <h3 className="text-xl text-cyan-700 font-semibold mb-2">{name}</h3>
          <p className="text-lg text-gray-700 mb-4">
            Thank you for your generous donation of Rupees <b>{amount}</b>. Your
            support greatly advances our mission at <b>Krishna Social Trust</b>.
            We deeply appreciate your commitment.
          </p>
          <p className="text-[12px] text-gray-600">
            Your Donation Certificate Sent to Your Email!
          </p>
          <a
            href="/"
            className="text-blue-500 font-semibold hover:text-blue-700"
          >
            Return to Homepage
          </a>
        </div>
      </div>

      {/* <div>
        <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
          <div className=" w-full bg-white shadow-lg rounded-lg p-8 border border-gray-300">
            <div className="text-center mb-8">
              <h1 className="text-md font-bold text-gray-800">
                Appreciation Certificate
              </h1>
              <p className="text-xl font-bold text-green-600 mt-16 ml-4">
                Krishna Social Trust
              </p>
              <p className="text-xs font-bold text-gray-500 mt-1 ml-4">
                Siddharth Nagar Near Front of Indian Oil Pettrol Pump U.P. - 272207
              </p>
            </div>

            <div className="text-start mb-8">
              <p className="text-lg text-gray-800"> Dear <b>{name}</b>,</p>
              <p className="text-lg text-gray-600 mt-2">
               
                Thanks to your generosity On behalf of <b>Krishna Social Trust</b>, we
                want to express our heartfelt gratitude for your generous
                support. Your recent donation of <b>₹{amount}</b> has made a
                significant impact on our mission.
              </p>
            </div>
            <div className="text-start mb-8 ">
              <table className='border-2 m-auto '>
                <tr className='border-2'>
                  <td className="text-md text-gray-600 p-1">
                    Donation Date :- {donationDate}
                  </td>
                </tr>
                <tr className='border-2'>
                  <td className="text-md text-gray-600 p-1">
                    Donation Amount :- ₹{amount}-/
                  </td>
                </tr>
                <tr className='border-2' >
                  <td className="text-md text-gray-600 p-1">
                    Payment Id :- {paymentId}
                  </td>
                </tr>
                <tr className='border-2'>
                  <td className="text-md text-gray-600 p-1">
                    Event Id :- {eventId}
                  </td>
                </tr>
              </table>
            </div>
            <div className="flex justify-between mt-12">
              <div className="text-center">
                <p className="text-lg text-gray-800">Authorized Signature</p>
                <div className="mt-4 h-16 border-t border-gray-400"></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ThankYou;
