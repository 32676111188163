import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Event from "./Event";
import AllBlogs from "./AllBlogs";
import WeddingPoor from "./work/wedding/WeddingPoor";
import OrphanChild from "./work/orphan/OrphanChild";
import HomelessPeople from "./work/homeless/HomelessPeople";
import Health from "./work/health/Health";
import ChildEducation from "./work/ChildEducation";
import axios from "axios";

const Home = () => {
 
  const [changimg, setChanimg] = useState(3);

  setTimeout(
    () => setChanimg(Math.floor(Math.random() * (6 - 1 + 1)) + 1),
    6000
  );


  useEffect(() => {
    const viewsFun = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/totalviews`);
             console.log("Total views",response)
        } catch (err) {
            console.error("Error fetching messages:", err);
        }
    };

    viewsFun();
}, []);


  return (
   
    <div>
      
      <div>
        <img
          src={`./img/${changimg}.jpg`}
          alt="img"
          className="lg:w-full lg:h-96"
        />
      </div>

      {/* -------------------------------------------------------- */}
      <div className="flex justify-between items-center m-1 p-1 text-xs md:text-md lg:text-lg font-semibold text-center md:mb-10">
        <Link  to="/child-eduction" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/education.jpg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p className="p-1 mx-0.5 bg-teal-300 rounded-sm">Child Education</p>
        </Link>
        <Link to="/orphan-child" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/orphan.jpg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p  className="p-1 mx-0.5 bg-blue-300 rounded-sm cursor-pointer hover:text-blue-400">Orphan Child</p>
        </Link>
        <Link to="/homeless-people" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/oldage.webp"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p  className="p-1 mx-0.5 bg-green-300 rounded-sm">Homeless People</p>
        </Link>
        <Link to="/wedding-poor" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        
          <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/wedddd.avif"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p  className="p-1 mx-0.5 bg-red-300 rounded-sm">Wedding of Poor Girl</p>
        </Link>
        <Link to="/emergency-health" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/health.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p className="p-1 mx-0.5 bg-yellow-300 rounded-sm">Health Emergency</p>
        </Link>
        
      </div>

      
      <div className="flex justify-between items-center m-1 p-1 text-xs md:text-md lg:text-lg font-semibold text-center md:mb-10">
        <Link  to="/all-donation" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/donation.jpg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p className="p-1 bg-red-200 rounded-sm">Donation</p>
        </Link>
        <Link to="/event" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/event.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p  className="p-1 bg-green-200 rounded-sm cursor-pointer hover:text-blue-400">Social Work</p>
        </Link>
        <Link to="/gallery" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/program.jpeg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p  className="p-1 bg-blue-200 rounded-sm">Activities</p>
        </Link>
        <Link to="/volunteer" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/Volunteers.jpg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p  className="p-1 bg-orange-200 rounded-sm">Volunteer</p>
        </Link>
        <Link to="/allblogs" className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80">
        <div className="h-14 md:h-32 lg:h-48 m-auto">
          <img
            src="./img/icons/blog.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          </div>
          <p className="p-1 bg-teal-200 rounded-sm">Blogs</p>
        </Link>
        
      </div>
     

       <div>
          <p className="text-md p-2 text-gray-600 font-bold text-center my-6">YOU CAN MAKE THEM SMILE</p>
          <Event/>
          <WeddingPoor/>
          <ChildEducation/>
          <OrphanChild/>
          <HomelessPeople/>
          <Health/>

         
       </div>
       <Footer/>
    </div>
  );
};

export default Home;
