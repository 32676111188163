import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const MembershipDetails = () => {
  const [membership, setmembership] = useState();
  
  const data = localStorage.getItem('userData');
  const userData = JSON.parse(data);


  let id = userData?.id
  const Navigate = useNavigate();
  let token = userData?.token
  const getUserFun = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/getsingleuser/${id}`)
      .then((res) => {
        setmembership(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!token) {
      Navigate("/");
    }
    getUserFun();
  }, []);
  return (
    <div>
      <div className="bg-gray-200 shadow-xl text-md text-gray-700 m-auto md:flex justify-between items-center p-3">
        {membership?.subscribe?.map((res) => (
          <>
          
            <div className="bg-blue-300 shadow-md rounded-md p-2 my-1">
              <p className="font-bold">Status :- <span className="font-semibold">{res.isActive==true?"Active":"Inactive"}</span></p>
              <p>Membeship Id :- <span className="font-semibold">{res.customerId}</span></p>
            </div>
            <div className="bg-blue-300 shadow-md rounded-md p-2 my-1">
              <p>Start Date:- <span className="font-semibold">{new Date(res.startDate).toLocaleString()}</span> </p>
              <p>End Date:- <span className="font-semibold">{new Date(res.endDate).toLocaleString()}</span> </p>
            </div>
            <div className="bg-blue-300 shadow-md rounded-md p-2 my-1">
              <p>plan Duration :- <span className="font-semibold">{res.month_count} {res.month}</span></p>
              <p>Price :- <span className="font-semibold">${res.price/Math.pow(10, 2)}.00</span></p>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
