import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import dateFormat from "dateformat";

const EventDetails = () => {
  const [eventDetail, setEventDetail] = useState();
  const [selectedImg, setSelectedImg] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDonar, setTotalDonar] = useState(0);
  const [loader, setLoader] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const DetailFun = async () => {
      setLoader(true);
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/getallevent`)
        .then((res) => {
          const data = res.data;
          const eventDetail = data?.filter((item) => item._id == id);

          setEventDetail(eventDetail);
          setLoader(false);
        })
        .catch((error) => {
          console.log("something went wrong" + error);
        });
    };
    DetailFun();
  }, [id]);

  const handleImageClick = (img) => {
    setSelectedImg(img);
  };

  const closeFullScreen = () => {
    setSelectedImg(null);
  };
  // ----------------------------------------all payment for this event---------------------------
  const totalPaymentFun = async (eventId) => {
    try {
      // Fetch data from the API
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/show-donation`
      );
      const allData = response?.data;

      // Check if data exists
      if (!allData) {
        console.log("No data received.");
        return;
      }

      // Filter data by eventId
      const filteredData = allData?.data?.filter(
        (item) => item?.eventId === id
      );

      setTotalDonar(filteredData.length);
      const totalAmount = filteredData?.reduce(
        (sum, item) => sum + (item?.donatedAmount || 0),
        0
      );

      setTotalAmount(totalAmount);
    } catch (error) {
      console.error("Something went wrong:", error);
    }
  };

  // -----------------------------------------------------------------------------------
  useEffect(() => {
    totalPaymentFun();
  }, []);

  // ---------------------------------------------------------

  const handleShare = () => {
    const url = window.location.href; // Get the current URL

    if (navigator.share) {
      navigator
        .share({
          title: "Check this out!",
          url: url,
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.error("Share failed:", error));
    } else {
      // Fallback for browsers that don't support the Web Share API
      alert("Sharing is not supported in this browser. Copy this URL: " + url);
    }
  };

  return (
    <div>
      {loader ? (
        <span className="font-bold text-center ml-4 text-md text-blue-500 p-2">
          Loading...
        </span>
      ) : (
        ""
      )}
      {eventDetail?.map((res, i) => (
        <div className="lg:flex-col justify-between items-start" key={i}>
          <div className="mt-0.5 grid grid-cols-4">
            {res?.postimg?.map((img, index) => (
              <img
                key={index}
                src={img}
                alt="img"
                className="p-1 h-32 md:h-80 md:full w-full cursor-pointer"
                onClick={() => handleImageClick(img)}
              />
            ))}
          </div>
          {selectedImg && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70">
              <div className="relative">
                <img
                  src={selectedImg}
                  alt="full size"
                  className="max-w-full max-h-full md:h-90 md:w-80"
                />
                <button
                  className="absolute top-2 right-2 text-white text-xl bg-gray-800 rounded-full p-1 hover:bg-gray-600"
                  onClick={closeFullScreen}
                >
                  &times;
                </button>
              </div>
            </div>
          )}
          <div className="lg:mt-2 shadow-md">
            <div className="flex-col md:flex justify-between items-center pb-2 bg-gray-100">
              <p className="bg-gray-100 ml-0 p-2 font-bold text-lg text-center">
                {res?.title}
              </p>
              <div className="flex justify-between items-center">
                <p className="mx-1 ml-4 text-xs">
                  Created by{" "}
                  <i className="text-teal-600 font-semibold">{res.username}</i>
                </p>
                <img
                  src={res.userprofile}
                  alt="img"
                  className="h-10 w-10 rounded-full mt-2 mr-2"
                />
              </div>
            </div>
            <div className="flex-col md:flex justify-between items-center pb-2 bg-gray-100 p-4">
              <p className="my-2 font-semibold text-teal-700 text-xs">
                <b>Date:- </b>
                {dateFormat(res.eventdate, "dddd, mmmm dS, yyyy")}{" "}
                <b>Time:- </b>
                {res.eventtime}
              </p>
              <p className="my-2 font-semibold text-blue-800 text-sm">
                <b>Location:-</b> {res.location}
              </p>
              <div className="flex justify-between items-center mt-4">
                {res.status ? (
                  <>
                  <Link
                    to={`/payment/${res?._id}`}
                    className="bg-red-500 text-sm  lg:mx-2 font-semibold cursor-pointer p-1 px-2 rounded-sm text-white hover:bg-red-600"
                  >
                    Donate Now
                  </Link>
                     <p
                     classname="bg-green-500 font-semibold text-sm cursor-pointer p-1 px-2 rounded-sm text-white hover:bg-green-600"
                     onClick={handleShare}
                   >
                     <span className="bg-green-500 py-1 px-2 text-white font-semibold rounded-sm">
                       Share Event
                     </span>
                   </p>
                   </>
                ) : (
                <>
                 
                    <p className="my-2 font-semibold text-lg text-red-500">This Event has been completed!</p>
    
                      <p
                      classname="bg-green-500 font-semibold text-sm cursor-pointer p-1 rounded-sm text-white hover:bg-green-600"
                      onClick={handleShare}
                    >
                      <p className="bg-green-500 py-1 px-2 text-white font-semibold rounded-sm">
                        Share
                      </p>
                    </p>
                </>
                )}
             
              </div>
              <div className="flex justify-between items-center">
                <p className="lg:ml-10 pt-2 text-sm font-semibold text-cyan-500">
                  Total Donation :{" "}
                  <span className="text-cyan-600">₹ {totalAmount}</span>
                </p>
                <p className="lg:ml-10 pt-2 text-sm font-semibold text-cyan-500">
                  Total Donor's :{" "}
                  <span className="text-cyan-600">({totalDonar})</span>
                </p>
              </div>
            </div>

            <p className="p-3 mb-4 text-sm font-semibold">
              <span className="text-teal-700 font-bold">Description :-</span>{" "}
              {res?.disc}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventDetails;
