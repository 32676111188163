import React, { useEffect, useState } from 'react'
import {Link, NavLink, Navigate } from 'react-router-dom'
import "../index.css"
import {useNavigate } from 'react-router-dom';
const Navbar = () => {
 const Navigate=useNavigate()

 const [toggle,setToggle]=useState(false)
 
 const data = localStorage.getItem('userData');
 const userData = JSON.parse(data);
 var profile = userData?.profile
 var name = userData?.name
 var isToken = userData?.token

 

  return (
    <>

     

     <div className='sticky top-0 flex items-center h-12 bg-yellow-400 w-full'>
        <Link to="/" className='lg:w-1/3 text flex justify-start items-center'>
           <img src="./img/icons/navlogo1.png" alt="logo" className='h-10 p-1' />
           {/* <span className='font-bold text-green-700 ml-3'>Lumbini Foundation</span> */}
       
        </Link>
        <div className='hidden md:w-full md:flex lg:w-full lg:flex justify-center items-center  no-underline list-none'>
            <ul className='flex justify-between items-center'>
            <NavLink to="/" className="p-1 px-2 text-md font-semibold cursor-pointer rounded-sm" >Home</NavLink>
            <NavLink to="/event" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'>Event</NavLink>
            <NavLink to="/gallery" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'>Activities</NavLink>
            <NavLink to="/member" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'>Members</NavLink>
            <NavLink to="/volunteer" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'>Volunteer</NavLink>
            <NavLink to="/allblogs" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'>Blogs</NavLink>
            <NavLink to="/about" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'>About</NavLink>
            <NavLink to="/contactus" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'>Contact</NavLink>
            </ul>
           
        </div>

    
      {
        isToken?<Link to="/profile" className='flex justify-between items-center ml-auto md:mr-[60px]'>
        <img src={profile} alt="img" className='h-10 w-10 rounded-full mr-4' />

        <p className='font-bold text-xs text-gray-500'>{ name?.length>11?`${name?.substring(0,11)}.`:name}</p>
       
          </Link>:""
          
      }
     
    
       {
        !isToken? <div className='hidden mr-2 md:inline-block'>
        <NavLink to="/login" className='bg-blue-500 hover:bg-gray-500 text-white font-semibold py-1 px-4 border border-blue-700 rounded'>join</NavLink>            
    </div>:""
       }
      
        <div className='ml-auto md:hidden'>
        <img src="./img/icons/hamberger.png" alt="ham" className='h-8 md:hidden m-1' onClick={()=>setToggle(!toggle)} /> 
        </div>
    </div>

       {/* ------------- for toggle and responsive--------------------- */}
       <div className={`${toggle?"fixed w-full":"hidden"} justify-center items-center  no-underline list-none md:hidden`}>
            <ul className='flex flex-col justify-between items-center m-auto bg-gray-200 w-auto pb-4'>
            <NavLink to="/" className="p-1 px-2 text-md font-semibold cursor-pointer rounded-sm" onClick={()=>setToggle(false)} >Home</NavLink>
            <NavLink to="/event" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'onClick={()=>setToggle(false)}>Events</NavLink>
            <NavLink to="/gallery" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'onClick={()=>setToggle(false)}>Activities</NavLink>
            <NavLink to="/member" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'onClick={()=>setToggle(false)}>Members</NavLink>
            <NavLink to="/volunteer" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'onClick={()=>setToggle(false)}>Volunteer</NavLink>
            <NavLink to="/allblogs" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'onClick={()=>setToggle(false)}>Blogs</NavLink>
            <NavLink to="/about" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'onClick={()=>setToggle(false)}>About</NavLink>
            <NavLink to="/contactus" className='p-1 px-2 text-md font-semibold cursor-pointer rounded-sm'onClick={()=>setToggle(false)}>Contact</NavLink>
           {
            !isToken? <NavLink to="/login" className="bg-blue-500 hover:bg-blue-700 text-white font-semibold mt-3 py-1 px-4 border border-blue-700 rounded" onClick={()=>setToggle(false)}>join</NavLink> :""
           }           
     
            </ul>
            
      
        </div>
        </>

  )
}

export default Navbar