import React from "react";
import { Link } from "react-router-dom";

const ThanksMessage = () => {
  return (
    <div
      className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('/img/icons/thanks.gif')" }}
    >
      <div className="relative -mt-6 max-w-md mx-auto p-8 bg-gradient-to-r from-gray-200 to-green-300 shadow-lg rounded-lg text-center z-10">
        <h1 className="text-3xl font-extrabold text-blue-600 mb-4">
          Thank You for Contacting Us!
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          Your message has been sent successfully. Our team will respond shortly! 
          Please check your email daily for updates.
        </p>
        <Link 
          to="/"
          className="inline-block px-6 py-2 text-lg font-semibold text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-200"
        >
          Return to Homepage
        </Link>
      </div>
    </div>
  );
};

export default ThanksMessage;
