import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
const Allusers = () => {
  const Navigate=useNavigate()
  let admin=localStorage.getItem("admin")
    const [alluser,setAlluser]=useState()
    const [loader,setLoader]=useState(false)
    const getAllUsers=async()=>{
      setLoader(true)
      await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallusers`)
      .then((res)=>{
          
           setAlluser(res.data)
           setLoader(false)
      }).catch((error)=>{
        console.log(error)
        setLoader(false)
      })
     
    }
 
    useEffect(()=>{
      if(!admin){
        return Navigate("/")
        }
      getAllUsers()
    },[])

    const deleteUser = async (id) => {
      // Display a confirmation dialog
      const confirmed = window.confirm("Are you sure you want to delete this user?");
      
      // If the user cancels, exit the function
      if (!confirmed) {
        return; // Exit the function if the user does not confirm
      }
    
      setLoader(true);
      
      try {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/deleteuser/${id}`);
        setLoader(false);
        getAllUsers();
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    };
    
  return (
    <>
    <div className="text-center font-bold text-lg text-gray-300 bg-teal-700 ">
        <p className="p-1">Our Dedicated Team</p>
        
    </div>
    <p className='font-bold text-center text-md text-blue-500 p-2'>{loader?"Loading...":""}</p>
    <div className="grid grid-cols-2 text-center gap-2 m-1 md:grid-cols-4 lg:grid-cols-4">
     {
      alluser?.map((res,i)=>(
        
        <div  className="bg-gray-100 shadow-md rounded-sm cursor-pointer my-1 ">
        <Link to={`/view-member-details-admin/${res?._id}`}>
        <img
          src={res?.profile}
          alt="img"
          className="h-24 w-24 rounded-full m-auto flex justify-center lg:h-32 lg:w-32 mt-1"
        />
        </Link>
        <div className="my-3">
          <p className="mt-2 text-sm font-semibold text-gray-600">
          {res?.name}
          </p>
          <i className="text-sm font-semibold text-blue-600">
            {res?.isAdmin?<span className='text-teal-500'>Admin</span>:"User"}
          </i>
          <Link to={`/edit-member-admin/${res?._id}`} className="m-2 text-sm font-semibold text-gray-600">
            <span className='text-yellow-500 text-sm p-2'>Edit</span>
          </Link>
          <span className='text-red-500 text-sm p-2' onClick={()=>deleteUser(res?._id)}>Delete</span>
         
          <div className="flex justify-between items-center mt-4">
            <img
              src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1710323633/f_o1w8zc.png"
              alt="logo"
              className="h-3 cursor-pointer mx-2"
            />
            <img
              src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1710323659/x_zmiocs.png"
              alt="logo"
              className="h-3 cursor-pointer mx-2"
            />
            <img
              src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1710323666/y_p3lbmy.png"
              alt="logo"
              className="h-3 cursor-pointer mx-2"
            />
            <img
              src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1710323649/i_a0zg5h.png"
              alt="logo"
              className="h-3 cursor-pointer mx-2"
            />
          </div>
        </div>
      </div>
      ))
     }
     
     
      
      
      
    </div>
    
    
    </>
  )
}

export default Allusers