import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {useNavigate } from 'react-router-dom';

const CreateBlog = () => {
  const data = localStorage.getItem('userData');
  const userData = JSON.parse(data);
    var username = userData?.name
    var id = userData?.id
    var userprofile = userData?.profile

   

    const [postimg,setPostimg]=useState()
    const [loading,setLoading]=useState(false)
    const [message,setmessage]=useState('')
    const [status,setStatus]=useState()
    const [title,setTitle]=useState()
    const [disc,setDisc]=useState()
    const Navigate=useNavigate()
     useEffect(() => {
    const isToken = localStorage.getItem('token');
     if(!isToken){
        Navigate("/login")
     }
 }, [])
 const CreatepostFun=async(e)=>{
    e.preventDefault()

 setLoading(true)
   const formData= new FormData()
  
   formData.append("id",id)
   formData.append("title",title)
   formData.append("disc",disc)
   formData.append("username",username)
   formData.append("userprofile",userprofile)
   formData.append("postimg",postimg)

 

   axios.post(`${process.env.REACT_APP_BASE_URL}/v1/createBlog`,formData)
   .then((res)=>{
    setTitle('')
    setDisc('')
    setPostimg('')
    setLoading(false)
    setStatus(res.status)
    setmessage(res.data.message)
    setTimeout(() => {
      Navigate("/manageblog")
    }, 1000);

   }).catch((error)=>{
    setLoading(false)
       console.log(error)
       setmessage(error.response.data.message)
       setStatus(error.response.status)
   })
 }
  return (
    
    <div className='w-full max-w-xs m-auto mt-6'>
    <div className='text-center font-bold text-lg text-teal-800'>Create Blog</div>
    
    <form onSubmit={CreatepostFun} className='bg-white shadow-md rounded px-2 pt-3 pb-2 mb-4'>

    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
      Title
      </label>
      <input onChange={(e)=>setTitle(e.target.value)}  value={title} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Blog Title" />
    </div>
    {loading?<p className='text-center text-gray-200 font-semibold bg-blue-500 p-2 my-2 rounded-sm'>Please Wait...</p>:""}
    {status==400?<p className={`text-center text-gray-200 font-semibold ${status==400?"bg-red-600":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
    {status==200?<p className={`text-center text-gray-200 font-semibold ${status==200?"bg-green-700":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="textarea">
      Discription
      </label>
      <textarea onChange={(e)=>setDisc(e.target.value)} value={disc} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="textarea" placeholder="Discription" />
    </div>
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="post image">
      Post Image
      </label>
      <input onChange={(e)=>setPostimg(e.target.files[0])} required className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="post" type="file" accept='jpg/png/jpeg/webp' placeholder="Post image" />
    </div>
         
    <div class="flex items-center justify-between">
      <button class="bg-teal-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Create
      </button>
 
    </div> 
          
  

    </form>
    </div>
  )
}

export default CreateBlog