import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const EditBlog = () => {
    const { id } = useParams();
    const [postimg, setImage] = useState([]); // Initialize as an empty array
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(null);
    const [title, setTitle] = useState('');
    const [disc, setDisc] = useState('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const isToken = localStorage.getItem('token');
        if (!isToken) {
            navigate("/login");
        }
    }, [navigate]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallblog`)
            .then((res) => {
                const data = res.data;
                const filteredData = data.filter(item => item._id === id);
                console.log("filtered Blog", filteredData);
                if (filteredData.length > 0) {
                    const res = filteredData[0];
                    setTitle(res.title);
                    setDisc(res.disc);
                    setImage([res.postimg]); // Assuming postimg is a single URL
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [id]); // Added id as a dependency

    const updateFun = async (e) => {
        e.preventDefault();
        const data = {
            postimg,
            title,
            disc,
        };
        console.log(data);
        setLoading(true);
        try {
            const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/editvlog/${id}`, data); // Send data directly
            console.log("updated data", res);
            setMessage("Update successful!");
            setStatus(res.status);
            setTimeout(() => {
                navigate("/adminmanageevent");
            }, 2000);
        } catch (error) {
            console.log("error", error);
            setMessage("Update failed. Please try again.");
            setStatus(400);
        } finally {
            setLoading(false);
        }
    }

    const onselectFile = async (e) => {
        const cloud_name = "dkmsgd3ua";
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "trust-image");
        setLoader(true);
        try {
            const res = await axios.post(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, formData);
            const newImg = res.data.secure_url;
            setImage(prev => [...prev, newImg]); // Add the new image to the existing array
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const deleteProduct = (img) => {
        const filteredImg = postimg.filter(item => item !== img);
        setImage(filteredImg);
    }

    return (
        <div className='w-full max-w-xs m-auto mt-6'>
            <div className='text-center font-bold text-lg text-teal-800'>Edit Blog</div>
            <div className="flex flex-wrap border border-md p-2 lg:w-full m-auto">
                {postimg.map((img, i) => (
                    <div className="relative" key={i}> {/* Added key prop */}
                        <img src={img} alt="img" className="h-12 lg:h-32 m-1" />
                        <button
                            className="bg-red-400 rounded-sm px-1 -mt-1 absolute top-0 right-0"
                            onClick={() => deleteProduct(img)}
                        >
                            <p className="-mt-1">x</p>
                        </button>
                    </div>
                ))}
                <label htmlFor="photo" className="flex justify-center items-center ml-12">
                    <img src="https://res.cloudinary.com/dhflg8ro7/image/upload/v1709836925/3388964_aiaioa.png" alt="img" className="h-8 lg:h-12" />
                </label>
            </div>
            <form onSubmit={updateFun} className='bg-white shadow-md rounded px-2 pt-3 pb-2 mb-4'>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                        Title
                    </label>
                    <input onChange={(e) => setTitle(e.target.value)} value={title} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Blog Title" />
                </div>
                {loading && <p className='text-center text-gray-200 font-semibold bg-blue-500 p-2 my-2 rounded-sm'>Please Wait...</p>}
                {status === 400 && <p className='text-center text-gray-200 font-semibold bg-red-600 p-2 my-2 rounded-sm'>{message}</p>}
                {status === 200 && <p className='text-center text-gray-200 font-semibold bg-green-700 p-2 my-2 rounded-sm'>{message}</p>}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="textarea">
                        Description
                    </label>
                    <textarea onChange={(e) => setDisc(e.target.value)} value={disc} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" placeholder="Description" />
                </div>
                <div className="flex items-center justify-between">
                    <button className="bg-teal-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Update
                    </button>
                </div>
            </form>
            <form>
                <input
                    className="mt-1 outline-blue-400 text-md font-semibold rounded-sm border-2 border-gray-200 hidden"
                    type="file"
                    name="file"
                    id="photo"
                    accept="image/png,image/jpeg,image/jpg,image/webp"
                    onChange={onselectFile}
                />
            </form>
        </div>
    );
}

export default EditBlog;
