
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const AllMessage = () => {
    const [allMessage, setAllMessage] = useState([]);

    useEffect(() => {
        const fetchMessage = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/get/message`);

                const sortedMessages = response.data ? response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
        
                setAllMessage(sortedMessages); // Set the sorted messages
            
               
            } catch (err) {
                console.error("Error fetching messages:", err);
            }
        };

        fetchMessage();
    }, []);

    return (
        <div className='min-h-screen flex flex-col items-center py-8 bg-gray-100'>
            <h1 className='text-2xl font-bold mb-6'>All Messages</h1>
            <div className='flex flex-wrap justify-center'>
                {allMessage.length > 0 ? (
                    allMessage.map((mess, index) => (
                        <div key={index} className='max-w-sm w-full mx-4 mb-6'>
                            <div className='bg-white shadow-lg rounded-lg p-5 hover:scale-105 hover:shadow-xl'>
                                <div className='flex flex-col'>
                                    <p className='text-lg font-semibold'>{mess?.name}</p>
                                    <p className='text-gray-600'>{mess?.number}</p>
                                    <p className='text-gray-600'>{mess?.email}</p>
                                    <p className='text-gray-800 mt-2 ml-2'>{mess?.contactmessage}</p>
                                    <p className='text-center text-sm text-gray-500 mt-4'>Date: {new Date(mess?.createdAt).toLocaleDateString()}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className='text-lg text-gray-700'>No messages found.</p>
                )}
            </div>
        </div>
    );
};

export default AllMessage;

