

import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
const ContactUs = () => {
    const navigate = useNavigate();
    const [loader,setLoader]=useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number:'',
    contactmessage: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to server)

     setLoader(true)
try {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/send/message`,formData) 
    .then((res=>{
       
      setLoader(false)
        navigate("/thank/message");
    }))
} catch (error) {
    console.log("error",error)
    setLoader(false)
}
   


    // Reset form
    setFormData({
      name: '',
      email: '',
      contactmessage: '',
    });
  };

  return (
    <>
      <h2 className="text-xl font-bold my-6 text-center text-gray-800">Contact Us</h2>

      <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen bg-gradient-to-r from-yellow-500 to-fuchsia-500">
        <div className="w-full max-w-md m-4 p-8 space-y-4 bg-white rounded-lg shadow-lg transition duration-300 hover:shadow-xl">
          <h3 className="text-xl font-semibold text-gray-800">Get in Touch</h3>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-600">
              Mobile : <span className="font-semibold">+91 8115784664</span>
            </label>
          
            <label htmlFor="email2" className="block text-sm font-medium text-gray-600">
              Email : <span className="font-semibold">sankalpsocialtrust.help@gmail.com</span>
            </label>
          </div>
        </div>

        <div className="w-full max-w-md m-4 p-8 space-y-6 bg-white rounded-lg shadow-lg transition duration-300 hover:shadow-xl">
          <form onSubmit={handleSubmit} className="space-y-4">
            <h3 className="text-xl font-semibold text-gray-800">Send Us a Message</h3>
               {loader? <p className='text-blue-500 p-2 text-center font-semibold ml-auto text-md'>Please wait...</p>:""}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-600">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="block w-full p-3 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="block w-full p-3 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div>
              <label htmlFor="number" className="block text-sm font-medium text-gray-600">
                Mobile
              </label>
              <input
                type="number"
                id="number"
                name="number"
                value={formData.number}
                onChange={handleChange}
                required
                className="block w-full p-3 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div>
              <label htmlFor="contactmessage" className="block text-sm font-medium text-gray-600">
                Message
              </label>
              <textarea
                id="contactmessage"
                name="contactmessage"
                value={formData.contactmessage}
                onChange={handleChange}
                required
                className="block w-full p-3 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 transition duration-200"
                rows="4"
              />
            </div>
            <button
              type="submit"
              className="w-full px-4 py-2 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 transition duration-200"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;

