import axios from 'axios'
import React, { useState } from 'react'
import { Link,useNavigate} from 'react-router-dom'

const Join = () => {
  
  const navigate = useNavigate();
  const [loading,setLoading]=useState(false)
  const [message,setmessage]=useState('')
  const [status,setStatus]=useState()
  const [name,setName]=useState('')
  const [email,setEmail]=useState('')
  const [mobile,setMobile]=useState('')
  const [dob,setDob]=useState()
  const [gender,setGender]=useState('')
  const [password,setPassword]=useState()
  const [confpass,setConfpass]=useState()
  const [profile,setProfile]=useState()

const formData = new FormData();
formData.append("name",name);
formData.append("email",email);
formData.append("mobile",mobile);
formData.append("dob",dob);
formData.append("gender",gender);
formData.append("password",password);
formData.append("confpass",confpass);
formData.append("profile",profile);



// for (const [key, value] of formData) {
//   console.log( value)
// }

  const handleSubmit=async(e)=>{
      e.preventDefault()
      setLoading(true)
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/user/registration`,formData)
      .then((res) => {
        console.log(res)
       
        setName('')
        setEmail('')
        setDob('')
        setPassword('')
        setConfpass('')
        setGender('')
        setLoading(false)
        setStatus(res.status)
        setmessage(res.data.message)
        setTimeout(() => {
          navigate("/login")
        }, 2000);
      

      })
      
    } catch (error) {
      setLoading(false)
       console.log(error)
     
       setmessage(error.response.data.message)
       setStatus(error.response.status)
       
    }
      
  }
  return (
    <>
    <div className='w-full max-w-xs m-auto mt-6'>
      <div className='text-center font-bold text-lg text-teal-800'>User Registration</div>
      

    <form onSubmit={handleSubmit} className={`bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 ${loading?"opacity-30":"opacity-100"}`}>

    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
        Full Name
      </label>
      <input onChange={(e)=>setName(e.target.value)} required value={name} className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Full Name" />
    </div>
    
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Email">
      Email
      </label>
      <input onChange={(e)=>setEmail(e.target.value)} required value={email}  className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="email" placeholder="Valid Email" />
    </div>
        
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Email">
      Mobile
      </label>
      <input onChange={(e)=>setMobile(e.target.value)} required value={mobile}  className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="number" placeholder="Valid Mobile" />
    </div>
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="DOB">
      Date of Birth
      </label>
      <input onChange={(e)=>setDob(e.target.value)} required value={dob}  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="date" placeholder="dd-mm-yy" />
    </div>
    {status==400?<p className={`text-center text-gray-200 font-semibold ${status==400?"bg-red-600":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
    {status==200?<p className={`text-center text-gray-200 font-semibold ${status==200?"bg-green-700":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
    <label  className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
      Gender
      </label>
<div className='flex justify-between items-center'>
<div className="mb-2"> 
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="male">
      <input onChange={(e)=>setGender(e.target.value)}  value="male"   name="gender" className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline" id="radio" type="radio" placeholder="male" />
      Male
      </label>
    </div>
    <div className="mb-2"> 
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="female">
      <input onChange={(e)=>setGender(e.target.value)} value="female"   name="gender" className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline" id="radio" type="radio" placeholder="female" />
      Female
      </label>
    </div>
    <div className="mb-2"> 
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="others">
      <input onChange={(e)=>setGender(e.target.value)} value="others"  name="gender" className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline" id="radio" type="radio" placeholder="other" />
      Other
      </label>
    </div>
</div>

   
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pass">
      Password
      </label>
      <input onChange={(e)=>setPassword(e.target.value)} required value={password} className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="password" placeholder=" Password" />
    </div>
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="conpass">
      Confirm Password
      </label>
      <input onChange={(e)=>setConfpass(e.target.value)} required value={confpass}  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="password" placeholder="Confirm Password" />
    </div>
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profile">
      Profile Picture
      </label>
      <input onChange={(e)=>setProfile(e.target.files[0])} required className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="file" accept='jpg/png/jpeg/webp' placeholder="profile" />
    </div>
  
    {loading?<p className='text-center text-gray-200 font-semibold bg-blue-500 p-2 my-2 rounded-sm'>Please Wait...</p>:""}
    <div className="flex items-center justify-between mt-2">
      <button className="bg-red-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Register
      </button>
      <Link to="/login"className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
        Already Register
      </Link>
    </div> 
          
  

    </form>
    </div>
    </>
  )
}

export default Join