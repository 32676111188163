

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
const UserDeatilByAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState();
  const [gender, setGender] = useState("");
  const [isVolunteer, setIsvolunteer] = useState(Boolean);
  const [isAdmin, setAdmin] = useState(Boolean);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/getallusers`)
      .then((res) => {
        const data = res.data;
        const filterdData = data.filter((item) => item._id == id);
        console.log(filterdData);
        filterdData?.map(
          (res) => (
            setProfile(res?.profile),
            setName(res?.name),
            setEmail(res?.email),
            setDob(res?.dob),
            setMobile(res?.mobile),
            setGender(res?.gender),
            setAdmin(res?.isAdmin),
            setMessage(res?.message),
            setIsvolunteer(res?.isVolunteer)
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


 

  return (
    <>
      <div className="w-full max-w-xs m-auto mt-2">
        <div className="text-center font-bold text-lg text-teal-800">
         User Details
        </div>
        <p>{loading?"Loading...":""}</p>

        <div>
            <img src={profile} alt="profile" className="h-40 w-36 m-auto" />
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Full Name
            </label>
            <input
         
              required
              value={name}
              className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Full Name"
            />
          </div>
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Email
            </label>
            <input
          
              required
              value={email}
              className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Full Name"
            />
          </div>
          
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Email"
            >
              Mobile
            </label>
            <input
             
              required
              value={mobile}
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="number"
              placeholder="Valid Mobile"
            />
          </div>
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="DOB"
            >
              Date of Birth
            </label>
            <input
        
              required
              value={dob}
              className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="date"
              placeholder="dd-mm-yy"
            />
          </div>
          {status == 400 ? (
            <p
              className={`text-center text-gray-200 font-semibold ${
                status == 400 ? "bg-red-600" : ""
              } p-2 my-2 rounded-sm`}
            >
              {message}
            </p>
          ) : (
            ""
          )}
          {status == 200 ? (
            <p
              className={`text-center text-gray-200 font-semibold ${
                status == 200 ? "bg-green-700" : ""
              } p-2 my-2 rounded-sm`}
            >
              {message}
            </p>
          ) : (
            ""
          )}
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="gender"
          >
            Gender
          </label>
          <div className="flex justify-between items-center">
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="male"
              >
                <input
                
                  value="male"
                  checked={gender === "male"}
                  name="gender"
                  className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline"
                  id="radio"
                  type="radio"
                  placeholder="male"
                />
                Male
              </label>
            </div>
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="female"
              >
                <input
                 
                  value="female"
                  checked={gender === "female"}
                  name="gender"
                  className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline"
                  id="radio"
                  type="radio"
                  placeholder="female"
                />
                Female
              </label>
            </div>
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="others"
              >
                <input
                 
                  value="others"
                  checked={gender === "others"}
                  name="gender"
                  className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline"
                  id="radio"
                  type="radio"
                  placeholder="other"
                />
                Other
              </label>
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlfor="category"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Position
            </label>
            <select
              name="isVolunteer"
              value={isVolunteer}
           
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option className="text-sm font-semibold" value="" id="">
                Select
              </option>
              <option className="text-sm font-semibold" value="false" id="">
                Member
              </option>
              <option className="text-sm font-semibold" value="true" id="">
                Volunteer
              </option>
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlfor="category"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              User Type
            </label>
            <select
              name="isAdmin"
              value={isAdmin}
            
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option className="text-sm font-semibold" value="" id="">
                Select
              </option>
              <option className="text-sm font-semibold" value="false" id="">
                User
              </option>
              <option className="text-sm font-semibold" value="true" id="">
                Admin
              </option>
            </select>
          </div>

      
        </div>
      </div>
    </>
  );
};

export default UserDeatilByAdmin;
