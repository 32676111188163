

import axios from 'axios'
import React, { useState } from 'react'
import { Link,useNavigate} from 'react-router-dom'

const CreateChildEducation = () => {
    
    let data = localStorage.getItem('userData');
    let userData = JSON.parse(data);
    var UserId=userData?.id






  const navigate = useNavigate();
  const [loading,setLoading]=useState(false)
  
  const [message,setMessage]=useState('')
  const [status,setStatus]=useState()
  const [name,setName]=useState('')
  const [age,setAge]=useState('')
  const [gender,setGender]=useState('')
  const [fatherName,setFatherName]=useState('')
  const [address,setAddress]=useState('')
  const [disc,setDisc]=useState('')
  const [postimg,setPostimg]=useState()
  const [category,setCategory]=useState()
  

const formData = new FormData();
formData.append("name",name);
formData.append("UserId",UserId);
formData.append("age",age);
formData.append("gender",gender);
formData.append("fatherName",fatherName);
formData.append("address",address);
formData.append("disc",disc);
formData.append("category",category);
formData.append("postimg",postimg);


  const handleSubmit=async(e)=>{
      e.preventDefault()
      setLoading(true)
      postDataFun()
      
  }

  const postDataFun = async () => {
    try {
      // Make the POST request
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/create-child-education`,
        formData
      );
      
      // Handle the response
      setName('');
      setGender('');
      setLoading(false);
      setStatus(response.status);
      setMessage(response.data.message);
      
      // Navigate after 2 seconds
      setTimeout(() => {
        navigate("/child-education-page");
      }, 2000);
  
    } catch (error) {
      // Handle errors
      setLoading(false);
      console.log(error);
      setMessage(error.response?.data?.message || 'An error occurred');
      setStatus(error.response?.status || 500);
    }
  };
  
  return (
    <>
    <div className='w-full max-w-xs m-auto mt-6'>
    <div className=''>
    <Link to="/child-education-page" className="m-2 bg-blue-500 hover:bg-blue-700 text-sm font-semibold text-white py-1 px-2 rounded-sm">
    Back
   </Link>
    </div>
      <div className='text-center font-bold text-lg text-teal-800'>Child Education Registration</div>
      
      {loading?<p className='text-center text-gray-200 font-semibold bg-blue-500 p-2 my-2 rounded-sm'>Please Wait...</p>:""}

    <form onSubmit={handleSubmit} className={`bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 ${loading?"opacity-30":"opacity-100"}`}>

    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
        Full Name
      </label>
      <input onChange={(e)=>setName(e.target.value)} required value={name} className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Full Name" />
    </div>
    
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Email">
      Age
      </label>
      <input onChange={(e)=>setAge(e.target.value)} required value={age}  className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="number" placeholder="age" />
    </div>
        
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Email">
      Father Name
      </label>
      <input onChange={(e)=>setFatherName(e.target.value)} required value={fatherName}  className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Father Name" />
    </div>
   
     


    {/* --------------------------- */}
    <div className="mb-2">
    <label htmlfor="category" className="block text-gray-700 text-sm font-bold mb-2">
            Category <span className="text-red-600">*</span>
         
          </label>
          <select
              name="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
           
              <option className="text-sm font-semibold" value="childEducation" id="">
                Child Education
              </option>
              <option className="text-sm font-semibold" value="orphanChild" id="">
                Orphan Child
              </option>
              <option className="text-sm font-semibold" value="homelessPeople" id="">
                Homeless People
              </option>
              <option className="text-sm font-semibold" value="wedding" id="">
                Wedding of Poor Girls
              </option>
              <option className="text-sm font-semibold" value="healthEmergency" id="">
                Health Emergency
              </option>
           
             
           
            </select>
          </div>
    {/* --------------------------------- */}
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Email">
      Adress
      </label>
      <input onChange={(e)=>setAddress(e.target.value)} required value={address}  className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Adress" />
    </div>
    {status==400?<p className={`text-center text-gray-200 font-semibold ${status==400?"bg-red-600":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
    {status==200?<p className={`text-center text-gray-200 font-semibold ${status==200?"bg-green-700":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
    <label  className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
      Gender
      </label>
<div className='flex justify-between items-center'>
<div className="mb-2"> 
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="male">
      <input onChange={(e)=>setGender(e.target.value)}  value="male"   name="gender" className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline" id="radio" type="radio" placeholder="male" />
      Male
      </label>
    </div>
    <div className="mb-2"> 
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="female">
      <input onChange={(e)=>setGender(e.target.value)} value="female"   name="gender" className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline" id="radio" type="radio" placeholder="female" />
      Female
      </label>
    </div>
    <div className="mb-2"> 
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="others">
      <input onChange={(e)=>setGender(e.target.value)} value="others"  name="gender" className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline" id="radio" type="radio" placeholder="other" />
      Other
      </label>
    </div>
</div>


    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="conpass">
      Disc
      </label>
      <textarea onChange={(e)=>setDisc(e.target.value)} required value={disc}  className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="password" placeholder="Discription" />
    </div>
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profile">
      Upload Image
      </label>
      <input onChange={(e)=>setPostimg(e.target.files[0])} required className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="file" accept='jpg/png/jpeg/webp' placeholder="profile" />
    </div>
  
      
    <div className="flex items-center justify-between mt-2">
      <button className="bg-red-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Create
      </button>
    
    </div> 
          
  

    </form>
    </div>
    </>
  )
}

export default CreateChildEducation