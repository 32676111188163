import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const EditSocialWork = () => {
  const { id } = useParams();

  const [name, setName] = useState();
  const [fatherName, setFatherName] = useState();
  const [disc, setDisc] = useState();
  const [age, setDob] = useState();
  const [address, setAddress] = useState();
  const [status, setEventStatus] = useState();
  const [image, setImage] = useState();
  const [AlertMess, setAlertMess] = useState(false);
  const [loader, setLoader] = useState(false);

  const Navigate = useNavigate();

  useEffect(() => {
    const isToken = localStorage.getItem("token");

    if (!isToken) {
      Navigate("/login");
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/get-all-child-education`)
      .then((res) => {
        const data = res.data;
        const filterdData = data.filter((item) => item._id == id);

        filterdData?.map(
          (res) => (
            setName(res?.name),
            setFatherName(res?.fatherName),
            setDisc(res.disc),
            setAddress(res?.address),
            setDob(res?.age),
            setEventStatus(res?.status),
            setImage(res?.image)
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const updateFun = async (e) => {
    e.preventDefault();
    const data = {
      name,
      disc,
      age,
      fatherName,
      status,
      image,
      address,
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/v1/edit-all-child-education/${id}`,
        data
      );

      setAlertMess(true);
      setTimeout(() => {
        setAlertMess(false);
      }, 2000);
      Navigate("/child-education-page");
    } catch (error) {}
  };
  const onselectFile = async (e) => {
    const cloud_name = "dkmsgd3ua";
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "trust-image");
    setLoader(true);
    await axios
      .post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        formData
      )
      .then((res) => {
        const newimg = res.data.secure_url;
        setImage((image) => [...image, newimg]);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteProduct = (im) => {
    const filterdimg = image?.filter((item) => item !== im);
    setImage(filterdimg);
  };
  return (
    <div className="w-full max-w-xs m-auto mt-6">
      <div className="text-center font-bold text-lg mb-3 text-teal-800">
        Social Work
      </div>

      {/* ---------------------------------add more img------------------------ */}
      <p className="text-white bg-green-600 text-center font-semibold">
        {AlertMess ? "Details Updated Success!" : ""}
      </p>
      <p className="text-white bg-blue-600 text-center font-semibold">
        {loader ? "Image Uploading...!" : ""}
      </p>
      <div className="flex flex-wrap border border-md p-2 lg:w-full m-auto">
        {image?.map((img, i) => (
          <div className="relative">
            <img src={img} alt="img" className="h-12 lg:h-32 m-1 " />
            <button
              className="bg-red-400 rounded-sm px-1 -mt-1 absolute top-0 right-0 "
              onClick={() => deleteProduct(img)}
            >
              <p className="-mt-1">x</p>
            </button>
          </div>
        ))}
        <label
          htmlFor="photo"
          className="flex justify-center items-center ml-12"
        >
          <img
            src="https://res.cloudinary.com/dhflg8ro7/image/upload/v1709836925/3388964_aiaioa.png"
            alt="img"
            className="h-8 lg:h-12"
          />
        </label>
      </div>
      {/* ----------------------------------------end------------------------------------------- */}
      <form
        onSubmit={updateFun}
        className="bg-white shadow-md rounded px-2 pt-3 pb-2 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="title"
          >
            Name
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Blog Title"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="title"
          >
            Fathers Name
          </label>
          <input
            onChange={(e) => setFatherName(e.target.value)}
            value={fatherName}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Blog Title"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="title"
          >
            Age in year
          </label>

          <input
            onChange={(e) => setDob(e.target.value)}
            value={age}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="number"
            placeholder="Age"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="title"
          >
            Adress
          </label>
          <input
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Event Location"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="textarea"
          >
            Discription
          </label>
          <textarea
            onChange={(e) => setDisc(e.target.value)}
            value={disc}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="textarea"
            placeholder="Discription"
          />
        </div>
        <div className="mb-2">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="post image"
          >
            Current Status :{" "}
            {status ? (
              <span className="text-green-500"> Active </span>
            ) : (
              <span className="text-red-500"> Inactive </span>
            )}
          </label>
          <input
            onChange={(e) => setEventStatus(e.target.value)}
            value="true"
            name="status"
            type="radio"
          />{" "}
          Active
          <input
            onChange={(e) => setEventStatus(e.target.value)}
            value="false"
            name="status"
            type="radio"
            className="ml-4 py-2 mb-5"
          />{" "}
          Inactive
        </div>

        <div class="flex items-center justify-between">
          <button class="bg-teal-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Update Event
          </button>
        </div>
      </form>

      <form>
        <input
          className="mt-1 outline-blue-400 text-md font-semibold rounded-sm border-2 border-gray-200 hidden"
          type="file"
          name="file"
          id="photo"
          accept="image/png,image/jpeg,image/jpg,image/webp"
          onChange={(e) => onselectFile(e)}
        />
      </form>
    </div>
  );
};

export default EditSocialWork;
