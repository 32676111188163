import React from "react";

const About = () => {
  return (
    <div className="flex flex-col">
      {/* <div>
        <img src="./img/6.jpg" alt="img" className="lg:hidden" />
      </div> */}
      <div className="m-2">
        <i className="text-red-500">About Us</i>
        <p className="font-semibold text-md">Sankalp Social Trust</p>
        <p className="mt-2">
          Sankalp Social Trust, a service oriented non-governmental organisation
          was established in the year 2024 with the noble intentions of feeding
          the hunger, helping the poor parents in their daughters' wedding, and
          giving hand to emergency patients. Taking up the selfless activities
          in the duty of motherland with the intention of giving back to the
          society. The trust does not run on any aid from the government and
          they have made it a point not to seek any grants. In a very short span
          of time, the trust earned a very good reputation. Sankalp Social Trust
          does not allow any political parties to take place in the trust's
          activities.
        </p>

        {/* <div className="text-center text-lg font-semibold rounded-md mt-2 p-1 bg-yellow-400">
          Our Aims
        </div> */}
        <p className="mt-6">
          <span className="font-semibold text-white bg-green-500 p-1 mr-1 rounded-sm"> BRIDES WEDDING : </span>
          <img src="./img/about/wedding1.webp" alt="img" className="mt-2 lg:hidden"/>
          <p className="mt-1">
          Our aim is to raise funds to support the families to get their
          daughters marry off in order to save them from ailments like dowry and
          suicide due to lack of funds. Sankalp Social Trust is founded with the
          aim to help families to get their daughters are married off in a
          dignified way. We aim at wedding off poor girls and save poor people
          from dowry and other sacrifices. We want everyone to come together and
          perform poor girls wedding so that she can lead her life happily
          </p>
        </p>
        <p className="mt-6">
          <span className="font-semibold text-white bg-blue-500 p-1 mr-1 rounded-sm"> FEEDING THE HUNGER : </span>
          <img src="./img/about/food1.jpg" alt="img" className="mt-2 lg:hidden"/>
         <p className="mt-1">
         Our aim is to feed the hungry homeless elderly, women, children,
         leprosy patients, disabled on the streets as part of zero hunger.
         </p>
        </p>
        <p className="mt-1">
          Weekly 500 hungry homeless get food donation, fruits in lunch. In
          India, many homeless people lives on public places of highways,
          railway stations and bus stops. Our aim to feed the hungry homeless
          destitutes, abandoned senior citizens, migrant workers.
        </p>
        <p className="mt-1">
          Sankalp Social Trust works to eradicate hunger, malnutrition, food
          wastage. Donate today to help us to serve the hungry homeless. In
          coming days, we are planning to feed 500 hungry on daily basis. You
          can also become part of it by joining as volunteers and donors for
          spreading smiles on hungry faces.
        </p>

        <p className="mt-6">
          <span className="font-semibold text-white bg-teal-500 p-1 mr-1 rounded-sm">EDUCATION : </span>
          <img src="./img/about/student2.jpg" alt="img" className="mt-2 lg:hidden"/>
         <p className="mt-1">
         The literacy rate is however increasing but the quality of education
          is reaching to those who can afford. However, it is not reaching to
          the ground level. The poor students who are in need of quality
          education are becoming unemployed. In this modern contemporary
          circumstances the poor students' understanding skills, logical
          thinking skills, and communication skills are not on par with the
          students who are from financially strong background.

         </p>
        </p>
        <p className="mt-1">
          As they do not have quality education, they are becoming incapable to
          competitive with the students who are from strong educational
          institutions.
        </p>

        <p className="mt-6">
          <span className="font-semibold text-white bg-pink-500 p-1 mr-1 rounded-sm">HOSPITALS : </span>
          <img src="./img/about/health1.jpg" alt="img" className="mt-2 lg:hidden"/>
           <p className="mt-1">
           Everyone's life is important. Due to financial upbringings, the poor
          people suffer a lot and it is reported that they also want to take
          services from super speciality hospitals. No act of kindness however
          small is ever wasted. Sankalp Social Trust helped so many patients and
          it became a helping hand to poor patients who are in need of services
          in time.
           </p>
        </p>
        <p className="mt-1">
          Sankalp Social Trust believes in it is not how much we give, but how
          much love we put into giving. So, helping the poor is very honest
          service. It is only shown by kind people. When we give cheerfully and
          accept gratefully, everyone is blessed. The best act of life is to
          serve the needy. In today's world, Sankalp Social Trust is remarked as
          one of the best non-profit organisations that gave hand to so many
          needy irrespective of locality, religion and region. It is reported
          that Sankalp Social Trust has a special role in the development of
          civil society. As Sankalp Social Trust became a most approaching
          helping hand, it needs some extra funds to help the needy.It is not
          wasted until and unless it reaches a right needy. Sankalp Social Trust
          wants to extend its services to the needy through-out country. The
          main intention of Sankalp Social Trust is taking initiation of serving
          needy so that no one can suffer from medical treatments. As the
          treatments at super speciality hospitals are very costly, Sankalp
          Social Trust needs donors and volunteers to build a hope for the needy
          patients. It is highly respected act if you become a part of serving
          needy patients with us.
        </p>
        <p className="mt-6">
          <span className="font-semibold text-white bg-violet-500 p-1 mr-1 rounded-sm">OLD AGE HOME : </span>
          <img src="./img/about/oldage.webp" alt="img" className="mt-2 lg:hidden"/>
          <p className="mt-1">

          Since the ancient ages, India's people would live in joint families
          who had parents, husband or wife, grandparents, children,
          grandchildren, uncles and cousins. It has been a custom to find that
          the parents and grandparents would take care of the house's infants
          and kids. In addition, when they grow up, they become a support system
          for the parents and grandparents too. When they fall ill, they take
          care, give them proper medical facilities and spend quality time with
          them. It is unfortunate to express that, now days they are not
          provided with such care and, attention and they are dumped in the old
          age homes. Due to the advent of urbanization, modernisation, several
          other factors the joint families have been shattered. People are busy
          with their jobs and the hardly have any time and interest to look
          after the older members of the family. Therefore, they leave their
          parents in old age home. In addition, many insensitive people abandon
          their parents and as a result, Sankalp Social Trust rescues them and
          admit them to old age homes. Many people who have no family to care of
          admit themselves to the old age homes volunteerly. Therefore, Suhana
          Foundation aims to establish old age homes through-out India.
          </p>
        </p>
        <p className="mt-6">
          <span className="font-semibold text-white bg-cyan-500 p-1 mr-1 rounded-sm">HANDICAPPED : </span>
          <img src="./img/about/handicap2.webp" alt="img" className="mt-2 lg:hidden"/>
          <p className="mt-1">
          As per census 2011, in India out of the total population of 121 crore,
          about 2.68 crore persons are disabled. Out of 2.68 crore, 1.5 crore
          are males and 1.18 crore are females. Majority of the disabled
          population resided in rural areas. Physically challenged people
          include blindness, low vision, leprosy cured persons, dwarfism,
          intellectual disability, mental illness, hearing impairment, acid
          attack victim, haemophilia, polio, etc.
          </p>
        </p>
        <p className="mt-1">
          Sankalp Social Trust is dreaming to help these physically challenged
          people in making them strong financially and physically. Suhana
          Foundation helped so many victims by distributing tri-cycles,
          artificial foot, paying medical bills etc., These physically
          challenged people are suffering a lot from hunger, inability of buying
          monthly medicines etc., Sankalp Social Trust helped so many needy
          victims. It also wants to serve India. Therefore, it needs volunteers
          and donors to fulfil the desires of the victims. God has given us an
          opportunity to become more virtuous by helping the physically
          challenged people.
        </p>
      </div>
    </div>
  );
};

export default About;
