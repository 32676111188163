import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const BlogDetail = () => {
    const [blogDetail,setBlogDetail]=useState()
    const [loader,setLoader]=useState(false)

    const {id}=useParams()
    useEffect(()=>{
     const DetailFun=async()=>{
      setLoader(true)
        await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallblog`)
        .then((res)=>{

            const data=res.data
            const blogdetail=data?.filter(item => item._id==id)
            setBlogDetail(blogdetail)
            setLoader(false)
        }).catch((error)=>{
            console.log("something went wrong"+error)
        })
     }
     DetailFun()
    },[])
  return (
    <div>
      {loader?<p className='text-center p-2 text-blue-500 font-semibold'>Loading...</p>:""}
       {blogDetail?.map((res,i)=>(
    <div className='lg:flex justify-between items-start' key={i}> 
     
   <div className='mt-1 lg:mt-12 lg:w-1/2 flex justify-center'>
       <img src={res?.postimg} alt="img" className='p-2 h-80' />
   </div>
   <div className='lg:mt-16'>
   <div className='flex justify-between items-center pb-2 bg-gray-100'>
        <p className='bg-gray-100 ml-1 p-1 font-bold text-md text-center'>{res?.title}</p>
        <div className='flex justify-between items-center'>
        <p className='mx-1 text-xs'>posted by <i className='text-teal-600 font-semibold'>{res?.username}</i></p>
        <img src={res?.userprofile} alt="img" className='h-10 w-10 rounded-full mt-2 mr-2' />
        </div>
     </div>
       <p className='p-3 text-sm font-semibold'>{res?.disc}</p>
   
   </div></div>
       ))}
        
    </div>
  )
}

export default BlogDetail