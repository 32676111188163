import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const AdminProfile = () => {
  const data = localStorage.getItem("userData");
  const userData = JSON.parse(data);
  let name = userData?.name;
  let admin = userData?.admin;

  const [totalViews, setTotalViews] = useState();
  const [dailyViews, setDailyViews] = useState();
  // const [hourlyViews, setHourlyViews] = useState(Array(24).fill(0)); // Default to an array of 24 zeros
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();

  useEffect(() => {
    if (!admin == "true") {
      Navigate("/");
    }
  }, []);

  const logoutFun = () => {
    localStorage.clear();
    Navigate("/");
  };




  
 

 

  useEffect(() => {
    const totalViewsFun = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/get/totalviews`);
         
             setTotalViews(response?.data)
        } catch (err) {
            console.error("Error fetching messages:", err);
        }
    };
    const dailyViewsFun = async () => {
      try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/dailyViews`);
         
           setDailyViews(response?.data)
      } catch (err) {
          console.error("Error fetching messages:", err);
      }
  };


  const fetchHourlyViews = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/hourlyViews`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // setHourlyViews(data.hourlyViews);
    } catch (error) {
      console.error("Error fetching hourly views:", error);
    } finally {
      setLoading(false);
    }
  };


    

    totalViewsFun();
    dailyViewsFun();

}, []);

  

  return (
    <>
      <div className="flex justify-end m-2">
        <p className="text-gray-500 font-bold mr-4">{"Hello " + name}</p>
        <button
          onClick={logoutFun}
          className="bg-red-600 m-auto text-white mx-2 p-1 px-2 text-xs font-semibold cursor-pointer rounded-sm focus:outline-none focus:shadow-outline"
        >
          Logout
        </button>
      </div>

      <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-2  m-1 p-1 text-xs md:text-md lg:text-lg font-semibold text-center md:mb-10">
        <Link
          to="/createblog"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/blog.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Create Blog</p>
        </Link>
        <Link
          to="/manageblog"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/event.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Yore Blogs</p>
        </Link>
        <Link
          to="/update-user-profile"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/program.jpeg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Edit Profile</p>
        </Link>
        <Link
          to="/admincreatevent"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1710344683/event-removebg-preview_pr7u6k.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Create Event</p>
        </Link>
        <Link
          to="/adminallpost"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1710346183/file-removebg-preview_x2oq17.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">AllPost</p>
        </Link>
        <Link
          to="/adminallusers"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1710344708/users-removebg-preview_se58wo.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">AllUser</p>
        </Link>
        <Link
          to="/adminmanageevent"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1710346709/copm_bqdoys.webp"
            alt="img"
            className="h-14 md:h-32 lg:h-48 p-1"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Event</p>
        </Link>
        <Link
          to="/admin-all-donation"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1710345924/donation_cotefw.jpg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Donation</p>
        </Link>
        <Link
          to="/child-education-page"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="/img/icons/services.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48 p-4"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Services</p>
        </Link>
        <Link
          to="/message/notification"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="/img/icons/notification.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Notification</p>
        </Link>
      </div>


      <div className="flex justify-center space-x-4 mt-6">
  <p className="p-2 text-gray-600 rounded-sm shadow-lg text-sm font-bold transition-transform duration-200 transform ">
    Total Visitors : {totalViews?.totalViews}
  </p>
  <p className="p-2 text-gray-600 rounded-sm shadow-lg text-sm font-bold transition-transform duration-200 transform ">
    Today's Visitors : {dailyViews?.dailyViews}
  </p>
</div>


 
    </>


  );
};

export default AdminProfile;
