import axios from "axios";
import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";

const Gallery = () => {
  const [allevent, setAllevent] = useState();
  const [allsocial, setAllsocial] = useState();
  const [loader,setLoader]=useState(false)
  const [loader1,setLoader1]=useState(false)

  const getAllEventFun = async () => {
    setLoader(true)
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/getallevent`)
      .then((res) => {
        const activeEvent = res.data?.filter((item) => item?.status == false);

        setAllevent(activeEvent);
        setLoader(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ---------------------------------------------------------------------------
  const getAllSocialFun = async () => {
    setLoader1(true)
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/get-all-child-education`)
      .then((res) => {
        const inactiveEvent = res.data?.filter((item) => item?.status == false);

        setAllsocial(inactiveEvent);
        setLoader1(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllEventFun();
    getAllSocialFun()
  }, []);
  return (
    <>
    {allevent?.length>0 ?<div className="text-center text-lg font-bold mb-1 text-gray-300 bg-teal-700 py-1">
        <p> Complited Event</p>
      </div>:""}
     
      <p className='font-bold text-center text-md text-blue-500 p-2'>{loader?"Loading...":""}</p>
      <div className="grid grid-cols-1 text-center gap-2 m-1 md:grid-cols-5">
        {allevent?.map((res, i) => (
          <Link
            to={`/admineventails/${res?._id}`}
            key={i}
            className="bg-gray-100 shadow-md rounded-sm cursor-pointer my-1 "
          >
            <img
              src={res?.postimg[0]}
              alt="img"
              className="hover:opacity-80 h-60 w-full"
            />
            <div className="my-3">
              <p className="my-2 text-xl font-bold text-gray-600">
                {res?.title?.length > 20
                  ? `${res?.title.substring(0, 20)}....`
                  : res?.title}
              </p>
              <p className="my-2 font-semibold text-teal-700">
                <b>Date:- </b>
                {dateFormat(res.eventdate, "dddd, mmmm dS, yyyy")}{" "}
                <b>Time:- </b>
                {res.eventtime}
              </p>
              <p className="my-2 font-semibold  text-blue-800">
                <b>Location:-</b> {res.location}
              </p>
              <p className="my-2 font-semibold text-lg  text-red-500">
                <p>This Event has been complited!</p>
              </p>
            </div>
          </Link>
        ))}
      </div>

      {allsocial?.length>0 ?<div className="text-center text-lg font-bold mb-1 text-gray-300 bg-teal-700 py-1">
        <p> Complited Social Work</p>
      </div>:""}
     
      <p className='font-bold text-center text-md text-blue-500 p-2'>{loader1?"Loading...":""}</p>
      <div className="grid grid-cols-1 text-center gap-2 m-1 md:grid-cols-5">
        {allsocial?.map((res, i) => (
          <Link
            to={`/details-page/${res?._id}`}
            key={i}
            className="bg-gray-100 shadow-md rounded-sm cursor-pointer my-1 "
          >
            <img
              src={res?.image[0]}
              alt="img"
              className="hover:opacity-80 h-60 w-full"
            />
            <div className="my-3">
              <p className="my-2 text-xl font-bold text-gray-600">
                {res?.title?.length > 20
                  ? `${res?.title.substring(0, 20)}....`
                  : res?.title}
              </p>
              <p className="my-2 font-semibold text-teal-700">
                <b>Date:- </b>
                {dateFormat(res?.eventdate, "dddd, mmmm dS, yyyy")}{" "}
                <b>Time:- </b>
                {res?.eventtime}
              </p>
              <p className="my-2 font-semibold  text-blue-800">
                <b>Location:-</b> {res?.location}
              </p>
              <p className="my-2 font-semibold text-lg  text-red-500">
                <p>This Event has been complited!</p>
              </p>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Gallery;
