import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

const ManageBlog = () => {
const Navigate=useNavigate()
const [blogs,setBlogs]=useState()
const [loader,setLoader]=useState(false)
const data = localStorage.getItem('userData');
const userData = JSON.parse(data);
  var id = userData?.id


const getblogFun=async()=>{
   setLoader(true)
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallblog`)
    .then((res)=>{
       
       const data=res.data
      
       const filterblog=data?.filter(item => item.id===id)

     
       setBlogs(filterblog)
       setLoader(false)
    }).catch((error)=>{
       console.log("API not Working"+error)
       setLoader(false)
    })
   }

    useEffect(() => {
        const isToken = localStorage.getItem('token');
         if(!isToken){
            Navigate("/login")
         }
    
       getblogFun()
     }, [])
   const editPost=(_id)=>{

   }

  //  const deletePost=async(id)=>{

  
  //   await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/deletepost/${id}`)
  //   .then((res)=>{
  //       console.log("Delete Success")
  //       getblogFun()
  //   }).catch((error)=>{
  //      console.log("API not Working"+error)
  //   })
  //  }
  const deletePost = async (id) => {
    // Prompt the user for confirmation before proceeding with the delete operation
    const confirmDelete = window.confirm("Are you sure you want to delete this post?");

    // If the user confirms the deletion, proceed with the API call
    if (confirmDelete) {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/deletepost/${id}`);
            console.log("Delete Success");
            getblogFun(); // Refresh or update the blog list after deletion
        } catch (error) {
            console.log("API not Working: " + error);
        }
    } else {
        console.log("Delete action canceled by user.");
    }
};

  return (
    <>
    {loader? <span className='font-bold text-center ml-4 text-md text-blue-500 p-2'>Loading...</span>:""}
     {blogs?.length==0?<p className='text-center'>Blogs Not Found</p> :""}
    <div className="grid grid-cols-1 text-center gap-2 m-1 md:grid-cols-3">
   {blogs?.map((res,i)=>(
        <>
        <p  className="shadow-md rounded-sm my-1 ">
        <Link to={`/blogdetails/${res?._id}`}>
        <img src={res?.postimg} alt="img" className="hover:opacity-80 h-80 w-full" />
        </Link>
        <div className="my-3">
        <p className="my-2 font-bold text-gray-600 text-lg">{res?.title?.length>30?`${res?.title.substring(0,30)}....`:res?.title}</p>
  
       <div className='flex justify-between items-center pb-2 bg-gray-100'>
       <div className='flex justify-between items-center'>
            <span className='ml-4 text-xs cursor-pointer text-blue-400 font-semibold' onClick={editPost()}>Edit</span>
            <span className='ml-6 text-xs cursor-pointer text-red-400 font-semibold' onClick={()=>(deletePost(res._id))}>Delete</span>
        </div>
          <div className='flex justify-between items-center'>
          <p className='mx-1 text-xs'>posted by <i className='text-teal-700 font-semibold'>{res.username}</i></p>
          <img src={res.userprofile} alt="img" className='h-10 w-10 rounded-full mx-2' />
          
          </div>
       </div>
        <p className='text-md'>{res?.disc?.length>150?`${res?.disc.substring(0,150)}....`:res?.disc}</p>
        </div>
      </p>
      
      </>
    ))
   }

   
   


     </div>
     </> )
}

export default ManageBlog