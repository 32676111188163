import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
const EditMemberByAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState();
  const [gender, setGender] = useState("");
  const [isVolunteer, setIsvolunteer] = useState(Boolean);
  const [isAdmin, setAdmin] = useState(Boolean);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/getallusers`)
      .then((res) => {
        const data = res.data;
        const filterdData = data.filter((item) => item._id == id);
        console.log(filterdData);
        filterdData?.map(
          (res) => (
            setName(res?.name),
            setDob(res?.dob),
            setMobile(res?.mobile),
            setGender(res?.gender),
            setAdmin(res?.isAdmin),
            setMessage(res?.message),
            setIsvolunteer(res?.isVolunteer)
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let UpdatedData = {
    name,
    mobile,
    dob,
    gender,
    isVolunteer,
    isAdmin,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/v1/updateuser/${id}`,
        { UpdatedData }
      );

  

      // Reset form fields and state
      setName("");
      setDob("");
      setGender("");
      setLoading(false);
      setStatus(response?.status);
      setMessage(response?.data?.message);
      setTimeout(()=>{
        navigate("/adminallusers");
      },500)
     
    } catch (error) {
      setLoading(false);
      console.error(error);

      // Handle the error response
      setMessage(error.response?.data?.message || "An error occurred");
      setStatus(error.response?.status || 500);
    }
  };

  return (
    <>
      <div className="w-full max-w-xs m-auto mt-6">
        <div className="text-center font-bold text-lg text-teal-800">
          Update User
        </div>
        <p>{loading?"Loading...":""}</p>

        <form
          onSubmit={handleSubmit}
          className={`bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 ${
            loading ? "opacity-30" : "opacity-100"
          }`}
        >
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Full Name
            </label>
            <input
              onChange={(e) => setName(e.target.value)}
              required
              value={name}
              className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Full Name"
            />
          </div>
          
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Email"
            >
              Mobile
            </label>
            <input
              onChange={(e) => setMobile(e.target.value)}
              required
              value={mobile}
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="number"
              placeholder="Valid Mobile"
            />
          </div>
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="DOB"
            >
              Date of Birth
            </label>
            <input
              onChange={(e) => setDob(e.target.value)}
              required
              value={dob}
              className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="date"
              placeholder="dd-mm-yy"
            />
          </div>
          {status == 400 ? (
            <p
              className={`text-center text-gray-200 font-semibold ${
                status == 400 ? "bg-red-600" : ""
              } p-2 my-2 rounded-sm`}
            >
              {message}
            </p>
          ) : (
            ""
          )}
          {status == 200 ? (
            <p
              className={`text-center text-gray-200 font-semibold ${
                status == 200 ? "bg-green-700" : ""
              } p-2 my-2 rounded-sm`}
            >
              {message}
            </p>
          ) : (
            ""
          )}
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="gender"
          >
            Gender
          </label>
          <div className="flex justify-between items-center">
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="male"
              >
                <input
                  onChange={(e) => setGender(e.target.value)}
                  value="male"
                  checked={gender === "male"}
                  name="gender"
                  className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline"
                  id="radio"
                  type="radio"
                  placeholder="male"
                />
                Male
              </label>
            </div>
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="female"
              >
                <input
                  onChange={(e) => setGender(e.target.value)}
                  value="female"
                  checked={gender === "female"}
                  name="gender"
                  className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline"
                  id="radio"
                  type="radio"
                  placeholder="female"
                />
                Female
              </label>
            </div>
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="others"
              >
                <input
                  onChange={(e) => setGender(e.target.value)}
                  value="others"
                  checked={gender === "others"}
                  name="gender"
                  className="text-gray-700 text-sm font-semibold mr-2 leading-tight focus:outline-none focus:shadow-outline"
                  id="radio"
                  type="radio"
                  placeholder="other"
                />
                Other
              </label>
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlfor="category"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Position
            </label>
            <select
              name="isVolunteer"
              value={isVolunteer}
              onChange={(e) => setIsvolunteer(e.target.value)}
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option className="text-sm font-semibold" value="" id="">
                Select
              </option>
              <option className="text-sm font-semibold" value="false" id="">
                Member
              </option>
              <option className="text-sm font-semibold" value="true" id="">
                Volunteer
              </option>
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlfor="category"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              User Type
            </label>
            <select
              name="isAdmin"
              value={isAdmin}
              onChange={(e) => setAdmin(e.target.value)}
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option className="text-sm font-semibold" value="" id="">
                Select
              </option>
              <option className="text-sm font-semibold" value="false" id="">
                User
              </option>
              <option className="text-sm font-semibold" value="true" id="">
                Admin
              </option>
            </select>
          </div>

          <div className="flex items-center justify-between mt-2">
            <button className="bg-red-400 hover:bg-blue-700 m-auto tex-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditMemberByAdmin;
