import axios from 'axios'
import React, { useState } from 'react'
import { Link,useNavigate} from 'react-router-dom'



const Login =() => {
  const [loading,setLoading]=useState(false)
  const [message,setmessage]=useState('')
  const [status,setStatus]=useState()
  const navigate = useNavigate();
  const [email,setEmail]=useState(null)
  const [password,setPassword]=useState(null)
 

 

  const handlsubmit=async(e)=>{
      e.preventDefault()

     const loginCredential={
        email, 
        password
      }
   
      setLoading(true)
      await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/userlogin`,loginCredential)
      .then((res=>{
       setEmail('')
       setPassword('')
   
       localStorage.setItem("token",res?.data?.token)
       localStorage.setItem("admin",res?.data?.validUser?.isAdmin)

      //  localStorage.setItem("id",res?.data?.validUser?._id)
      //  localStorage.setItem("name",res?.data?.validUser?.name)
      //  localStorage.setItem("email",res?.data?.validUser?.email)
      //  localStorage.setItem("profile",res?.data?.validUser?.profile)
      
      // Assuming res is the response object from an API call
const userData = {
  token: res?.data?.token,
  name: res?.data?.validUser?.name,
  id: res?.data?.validUser?._id,
  email: res?.data?.validUser?.email,
  profile: res?.data?.validUser?.profile,
  admin: res?.data?.validUser?.isAdmin,
  mobile:res?.data?.validUser?.mobile
};

// Convert the userData object to a JSON string and store it in localStorage
localStorage.setItem('userData', JSON.stringify(userData));



       setLoading(false)
       setStatus(res.status)
       setmessage(res.data.message)
       setTimeout(() => {
        navigate("/")
      }, 1000);
        
      }))
      .catch((error)=>{
        setLoading(false)
        console.log(error)
        setmessage(error.response.data.message)
        setStatus(error.response.status)
        
      })
  }
  return (
    <>

   <div className='w-full max-w-xs m-auto mt-6'>
   <div className='text-center font-bold text-lg text-teal-800'>User Login</div>
   
   <form onSubmit={handlsubmit} className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>

   <div className="mb-4">
     <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Email">
     Email
     </label>
     <input onChange={(e)=>setEmail(e.target.value)} value={email} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="email" placeholder="Valid Email" />
   </div>
   {status==400?<p className={`text-center text-gray-200 font-semibold ${status==400?"bg-red-600":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
    {status==200?<p className={`text-center text-gray-200 font-semibold ${status==200?"bg-green-700":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
   <div className="mb-4">
     <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
     Password
     </label>
     <input onChange={(e)=>setPassword(e.target.value)} value={password} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="password" placeholder="Password" />
   </div>
   
  {loading?<p className='text-center text-gray-700 font-semibold bg-blue-300 p-2 my-2 rounded-sm'>Please Wait...</p>:""}
   <div class="flex items-center justify-between">
     <button class="bg-teal-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
       Login
     </button>
     <Link to="/join" class="inline-block align-baseline font-bold text-sm text-red-400 hover:text-blue-800" href="#">
       New Registration
     </Link>
   </div> 
         
 

   </form>
   </div>
   
    
    
    </>
  )
}

export default Login