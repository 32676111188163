import axios from 'axios'
import React, { useEffect, useState } from 'react'
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom'

const Event = () => {
  const [allevent,setAllevent]=useState()
  const [loader,setLoader]=useState(false)
  
  const getAllEventFun=async()=>{
    setLoader(true)
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallevent`)
    .then((res)=>{
        const activeEvent=res.data?.filter(item => item?.status==true)
      setAllevent(activeEvent)
      setLoader(false)
    }).catch((error)=>{
      console.log(error)
      setLoader(false)
    })
   
  }

  useEffect(()=>{
    getAllEventFun()
  },[])
  return (
        <>
        {allevent?.length>0 ?  <div className='text-center text-lg font-bold mb-1 text-gray-300 bg-teal-700 py-2'>
            <p>Upcoming Social Event</p>
        </div>:""}
      
        <p className='font-bold text-center text-md text-blue-500 p-2'>{loader?"Loading...":""}</p>
         <div className="grid grid-cols-1 text-start gap-2 m-1 md:grid-cols-4">
             {allevent?.map((res,i)=>(
               <Link to={`/admineventails/${res._id}`} key={i} className="bg-gray-100 shadow-md rounded-sm cursor-pointer my-1 ">
               <img src={res?.postimg[0]} alt="img" className="hover:opacity-80 h-64 lg:h-60 w-full" />
               <div className="my-3">
               <p className="my-2 ml-2 text-md font-bold text-gray-600">{res?.title?.length>38?`${res?.title.substring(0,38)}...`:res?.title}</p>
               <p className='my-2 ml-2 text-xs font-semibold text-teal-700'><b>Date :- </b>{dateFormat(res.eventdate,"dddd, mmmm dS, yyyy")}  <b>Time :- </b>{res.eventtime}</p>
               <p className='my-2 ml-2 text-sm font-semibold  text-blue-800'><b>Location :- </b> {res.location}</p>
              <div className='flex justify-center items-center'>
              <Link to={`/payment/${res._id}`} className="bg-red-500 text-center font-semibold cursor-pointer p-1 px-2 rounded-sm text-white mt-3 hover:bg-red-600">support us</Link>
              </div>
               </div>
             </Link>
             ))}
            
         </div>
         </>
  )
}

export default Event

