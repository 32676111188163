import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const Paymen = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const data = localStorage.getItem("userData");
  const userData = JSON.parse(data);
  var memberName = userData?.name;
  var memberId = userData?.id;
  var memberMobile = userData?.mobile;
  var memberProfile = userData?.profile;
  var memberEmail = userData?.email;
  var token = userData?.token;

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [amount, setAmount] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventImg, setEventImg] = useState("");

  const name1=token?memberName:name
  const email1=token?memberEmail:email
  const mobile1=token?memberMobile:mobile
  const memberId1=token?memberId:""
  const memberProfile1=token?memberProfile:""


  const paymentData = {
    name:name1,
    email:email1,
    mobile:mobile1,
    amount,
    eventId,
    memberId:memberId1,
    profile:memberProfile1
    

  };


  useEffect(() => {
    getAllEventFun();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    CheckoutHandler();
  };
// -------------------------getevent-------------------------------------
  const getAllEventFun = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/getallevent`)
      .then((res) => {
        const activeEvent = res.data?.filter((item) => item?._id === id);
        activeEvent.map((res, i) => {
          setEventTitle(res?.title);
          setEventId(res?._id);
          setEventImg(res?.postimg);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
// ------------------------checkout handler--------------------------------------
  const CheckoutHandler = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/checkout`,
        { paymentData }
      );
      const { data } = response;
      PaymentVerify(data?.data); //verify paymet done or not
    } catch (error) {
      setLoading(false);
      console.error("Error during checkout:", error);
    }
  };

  // ----------------------------payment verify fun-------------------------------------------
  const PaymentVerify = async (data) => {
    const options = {
      key: "rzp_test_5hu2YiDKm2YBGP", // Enter the Key ID generated from the Dashboard
      amount: data?.amount,
      currency: "INR",
      name: "Sankalp Social Trust", // Your business name
      image:
        "https://res.cloudinary.com/dkmsgd3ua/image/upload/v1731737470/Asset_12_4x_lcfncu.png",
      order_id: data?.id, // Pass the `id` obtained in the response of Step 1
      notes: {},
      theme: {
        color: "#3399cc",
      },

      //calback
      handler: async (response) => {
        const sendDataToBD = {
          //send data to db
          response,
          UserInfo: data.notes,
        };
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/v1/paymentverification`,
            { sendDataToBD }
          );
          setLoading(false);
          console.log("donar details on payment",res)

        
          // ----------------------------------------------------------------------------------------
          navigate(`/thank-you/${res.data.payment._id}`);
        } catch (error) {
          console.log("error", error);
        }
      },
    };

    setLoading(false);
    const razor = new window.Razorpay(options);
    razor.open();
  };
  return (
    <>
      <div className="w-full max-w-xs m-auto mt-6">
        <Link to="/event">
          <p className="text-gray-800 font-bold">Back</p>
        </Link>
        {loading ? (
          <p className="text-center text-gray-200 font-semibold bg-blue-500 p-2 my-2 rounded-sm">
            Please Wait...
          </p>
        ) : (
          ""
        )}

        <div className="text-center font-bold text-sm p-1 m-1 text-blue-700">
          <span className="text-teal-700">Donate For</span> {eventTitle}
        </div>
        <div className="flex justify-center">
          {/* {eventImg?.map((res,i)=>(
           <img src={res} alt="img" className='h-48 w-80'/>
          ))} */}
          <img src={eventImg[0]} alt="img" className="h-48 w-80" />
        </div>

        <form
          onSubmit={handleSubmit}
          className={`bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 ${
            loading ? "opacity-30" : "opacity-100"
          }`}
        >
          {!token?( <>
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Name
            </label>
            <input
              onChange={(e) => setName(e.target.value)}
              required
              value={name}
              className="shadow text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Your Name"
            />
          </div>

          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Email"
            >
              Email
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              required
              value={email}
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="email"
              placeholder="Valid Email"
            />
          </div>

          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Email"
            >
              Mobile
            </label>
            <input
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="number"
              placeholder="Valid Mobile"
            />
          </div>
         
          </>)
          :""}
           <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Email"
            >
              Amount  ₹{amount}
            </label>
            <input
              onChange={(e) => setAmount(e.target.value)}
              required
              value={amount}
              className="shadow  text-sm font-semibold appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="number"
              placeholder="Enter The Amount"
            />
          </div>
          <div className="flex items-center justify-center mt-2">
            <button className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Proceed
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Paymen;
