import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'


const EditEvent = () => {
    const {id}=useParams()
 
    const [title,setTitle]=useState()
    const [disc,setDisc]=useState()
    const [eventdate,setDate]=useState()
    const [eventtime,setTime]=useState()
    const [location,setLocation]=useState()
    const [status,setEventstatu]=useState()
    const [postimg,setImage]=useState()
    const[AlertMess,setAlertMess]=useState(false)
    const [loader,setLoader]=useState(false)

    const Navigate=useNavigate()

     useEffect(() => {
    const isToken = localStorage.getItem('token');
   
     if(!isToken){
        Navigate("/login")
     }
 }, [])



    useEffect(()=>{
       
   axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallevent`)
   .then((res)=>{
    const data=res.data
    const filterdData=data.filter(item =>item._id==id)
     console.log(filterdData)
    filterdData?.map((res)=>(
      setTitle(res.title),
      setDate(res.eventdate),
      setTime(res.eventtime),
      setDisc(res.disc),
      setLocation(res.location),
      setEventstatu(res.status),
      setImage(res.postimg)

    ))

   }).catch((error)=>{
    console.log(error)
   })
    },[])
    const updateFun= async(e)=>{
      e.preventDefault()
    const data={
      title,
      disc,
      eventdate,
      eventtime,
      status,
      postimg,
      location

    }
console.log(data)
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/editevent/${id}`,data)
      .then((res)=>(
       console.log(res)
      ))
      setAlertMess(true)
      setTimeout(()=>{
        setAlertMess(false)
      },2000)
      Navigate("/adminmanageevent")
    } catch (error) {
      
    }

    }
const onselectFile= async(e)=>{
  const cloud_name="dkmsgd3ua"
  const file= e.target.files[0];
  const formData=new FormData()
    formData.append("file", file);
    formData.append("upload_preset","trust-image")
  setLoader(true)
  await axios
    .post(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,formData)
    .then((res)=>{ 
    
     const newimg= res.data.secure_url
      setImage(postimg=>[...postimg,newimg])
      setLoader(false)
    
   })
    .catch((error) => {
      console.log(error);
    });

}
const deleteProduct=(im)=>{
  const filterdimg=postimg?.filter(item => item!==im)
     setImage(filterdimg)
}
  return (
    <div className='w-full max-w-xs m-auto mt-6'>
    <div className='text-center font-bold text-lg mb-3 text-teal-800'>Upadate Upcoming Event</div>

    {/* ---------------------------------add more img------------------------ */}
    <p className="text-white bg-green-600 text-center font-semibold">
            {AlertMess ? "Details Updated Success!" : ""}
          </p>
          <p className="text-white bg-blue-600 text-center font-semibold">
            {loader ? "Image Uploading...!" : ""}
          </p>
    <div className="flex flex-wrap border border-md p-2 lg:w-full m-auto">
        {postimg?.map((img, i) => (
          <div className="relative">
            <img src={img} alt="img" className="h-12 lg:h-32 m-1 "/>
            <button
              className="bg-red-400 rounded-sm px-1 -mt-1 absolute top-0 right-0 "
              onClick={() => deleteProduct(img)}
            >
              <p className="-mt-1">x</p>
            </button>
          </div>
        ))}
         <label htmlFor="photo" className="flex justify-center items-center ml-12">
           <img src="https://res.cloudinary.com/dhflg8ro7/image/upload/v1709836925/3388964_aiaioa.png" alt="img" className="h-8 lg:h-12"/>
           </label>
      </div>
   {/* ----------------------------------------end------------------------------------------- */}
    <form onSubmit={updateFun} className='bg-white shadow-md rounded px-2 pt-3 pb-2 mb-4'>

    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
      Title
      </label>
      <input onChange={(e)=>setTitle(e.target.value)}  value={title} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Blog Title" />
    </div>
    
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
      Event Date
      </label>
      <DatePicker selected={eventdate} onChange={(eventdate) => setDate(eventdate)} className="shadow appearance-none border rounded w-[19rem] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
      {/* <input onChange={(e)=>setDate(e.target.value)}  value={eventdate} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="date" placeholder="Event Date" /> */}
    </div>
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
      Event Time
      </label>
     
      <input onChange={(e)=>setTime(e.target.value)}  value={eventtime} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="time" placeholder="Event Time" />
    </div>
    
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
      Event Location
      </label>
      <input onChange={(e)=>setLocation(e.target.value)}  value={location} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Event Location" />
    </div>
    
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="textarea">
      Discription
      </label>
      <textarea onChange={(e)=>setDisc(e.target.value)} value={disc} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="textarea" placeholder="Discription" />
    </div>
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="post image">
      Status
      </label>
     
      <input onChange={(e)=>setEventstatu(e.target.value)} value="true" name='eventsatatus'type="radio" /> Active
      <input  onChange={(e)=>setEventstatu(e.target.value)} value="false" name='eventsatatus'type="radio" className='ml-4 py-2 mb-5' /> Inactive
      
    </div>
         
    <div class="flex items-center justify-between">
      <button class="bg-teal-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Update Event
      </button>
     
    </div>  
    </form>
  


<form>   
<input
  className="mt-1 outline-blue-400 text-md font-semibold rounded-sm border-2 border-gray-200 hidden"
  type="file"
  name="file"
  id="photo"
  accept="image/png,image/jpeg,image/jpg,image/webp"
  onChange={(e)=> onselectFile(e)}
 
/>
</form>
    </div>
  )
}

export default EditEvent