// import React from 'react'







import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';


const HomelessAdmin = () => {
    const Navigate=useNavigate()
const [blogs,setBlogs]=useState()
const [loader,setLoader]=useState(false)




const getAllEventFun = async () => {
  setLoader(true)
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/get-all-child-education`)
      .then((res) => {
        const activeEvent = res.data?.filter(
          (item) => item?.status === true && item?.category === "homelessPeople"
        );
        setBlogs(activeEvent);
        setLoader(false)
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };

    useEffect(() => {
        const isToken = localStorage.getItem('token');
         if(!isToken){
            Navigate("/login")
         }
    
        //  UpdateEventFun()
        getAllEventFun()
     }, [])

    const activePost=()=>{

    }
   const editPost=(id)=>{
   
   }
   const deletePost=async(id)=>{

    await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/${id}`)
    .then((res)=>{
        console.log("Delete Success")
        // UpdateEventFun()
        getAllEventFun()
    }).catch((error)=>{
       console.log("API not Working"+error)
    })
   }
  return (
   <>

    <div className='mt-2 flex justify-between'>
     <div>
     <Link to="/child-education-page" className="m-2 bg-blue-500 hover:bg-blue-700 text-sm font-semibold text-white py-1 px-2 rounded-sm">
      Back
   </Link>
     </div>
     <div>
     <Link to="/create-child-education" className="m-2 bg-blue-500 hover:bg-blue-700 text-sm font-semibold text-white py-1 px-2 rounded-sm">
      Create New
   </Link>
     </div>
  
     
   
    </div>
    <p className='font-bold text-center text-md text-blue-500 p-2'>{loader?"Loading...":""}</p>
    <div className="grid grid-cols-1 text-start gap-2 m-1 md:grid-cols-3">
   {blogs?.map((res,i)=>(
        <>
        <Link to={`/details-page/${res?._id}`}className="shadow-md rounded-sm my-1 ">
        <img src={res?.image[0]} alt="img" className="hover:opacity-80 h-80 w-full" />
        <div className="my-3">
        <p className="my-2 ml-2 font-bold text-gray-600 text-lg">Name: {res?.name?.length>30?`${res?.name?.substring(0,30)}....`:res?.name}</p>
        <p className="my-2 ml-2 font-semibold text-gray-600 text-md">Fathers: {res?.fatherName?.length>30?`${res?.fatherName?.substring(0,30)}....`:res?.fatherName}</p>
        <p className="my-2 ml-2 font-semibold text-gray-600 text-md">Age: {res?.age}y</p>
        <p className="my-2 ml-2 font-semibold text-gray-600 text-md">Gender: {res?.gender}</p>
        <p className="my-2 ml-2 font-semibold text-gray-600 text-md">Address: {res?.address}</p>
        <p className='text-md'><span className='my-2 ml-2 font-semibold'>About: </span>{res?.disc?.length>45?`${res?.disc.substring(0,45)}....`:res?.disc}</p>
       <div className='flex justify-between items-center pb-2 bg-gray-100'>
       <div className='flex justify-between items-center'>
       <span className='ml-4 text-xs cursor-pointer text-blue-400 font-semibold' onClick={activePost()}>{res?.status?<span className='text-green-600'>Active</span>:<span className='text-yellow-600'>Inactive</span>}</span>
            <Link to={`/edit-social-work-admin/${res?._id}`} className='ml-4 text-xs cursor-pointer text-blue-400 font-semibold'>Edit</Link>
            <span className='ml-6 text-xs cursor-pointer text-red-400 font-semibold' onClick={()=>(deletePost(res?._id))}>Delete</span>
        </div>
          <div className='flex justify-between items-center'>
    
          <p className='mx-1 text-xs'>posted by <i className='text-teal-700 font-semibold'>{res?.createdBy[0]?.name}</i></p>
          <img src={res?.createdBy[0]?.profile} alt="img" className='h-10 w-10 rounded-full mx-2' />
          
          </div>
        
       </div>
   
        </div>
      </Link>
      
      </>
    ))
   }

   
   


     </div>
     </> )
}

export default HomelessAdmin