import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import UserProfile from '../user/UserProfile';


const AdminProtected = ({AdminProfile}) => {
    let navigate = useNavigate();

    let isToken =localStorage.getItem("token");
    let isAdmin = localStorage.getItem("admin");
    useEffect(() => {
    //   const userInfo = localStorage.getItem("UserInformation");
    //   const userdata = JSON.parse(userInfo);
   
      console.log(isToken)
      console.log(isAdmin)
      if (!isToken) {
        navigate("/login");
      }
      if (!isAdmin) {
        navigate("/");
      }
    }, []);
    
    if(isAdmin=="true" && isToken){
        return  <AdminProfile/>
       }
       if(isAdmin=="false" && isToken){
           return <UserProfile/>
       }
    
}

export default AdminProtected