import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(true);
  const { id, token } = useParams(); // Destructure parameters directly

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const url = `${process.env.REACT_APP_BASE_URL}/v1/verifyemail/${id}/${token}`;
        const { data } = await axios.get(url);
        console.log(data);
        setValidUrl(true);
      } catch (error) {
        console.error("Error verifying email:", error.message);
        setValidUrl(false);
      }
    };
    
    if (id && token) {
      verifyEmailUrl();
    } else {
      setValidUrl(false); // Handle missing parameters
    }
  }, [id, token]); // Use specific dependencies

  return (
    <>
      {validUrl ? (
        <div className="flex flex-col justify-center items-center h-screen">
          <img src="/img/success.png" alt="Success" className="w-20 h-20" /> {/* Adjusted path */}
          <h1 className="text-xl py-3">Email verified successfully</h1>
          <Link to="/login">
            <button className="bg-green-500 text-white px-3 py-1 rounded-md">
              Login
            </button>
          </Link>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-screen">
          <h1 className="text-xl py-3">404 Not Found</h1>
        </div>
      )}
    </>
  );
};

export default EmailVerify;
