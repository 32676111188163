

import axios from 'axios'
import React, { useState } from 'react'
import { Link,useNavigate} from 'react-router-dom'



const ChangePass =() => {
  const [loading,setLoading]=useState(false)
  const [message,setmessage]=useState('')
  const [status,setStatus]=useState()
  const navigate = useNavigate();
  const [currentPass,setCurrentPass]=useState(null)
  const [password,setPassword]=useState(null)
 
  const data = localStorage.getItem('userData');
  const userData = JSON.parse(data);
  let email=userData?.email
  const Navigate=useNavigate()
 

  const UpdatePassFun = async () => {
   

    await axios
    .put(`${process.env.REACT_APP_BASE_URL}/v1/update/password`,loginCredential)
    .then((res) => {

      
        setStatus(res?.status)
        setmessage(res?.data)
        setCurrentPass('')
        setPassword('')
       
    
    })
    .catch((error) => {
      console.log(error);
      setStatus(error?.response?.status)
      setmessage(error?.response?.data)
   
    });
};

  let loginCredential={
    currentPassword:currentPass, 
    newPassword:password,
    email:email
  }
  const handlsubmit=async(e)=>{
      e.preventDefault()
      UpdatePassFun()
     

    
   
  }
  return (
    <>

   <div className='w-full max-w-xs m-auto mt-6'>
   <div className='text-center font-bold text-lg text-teal-800'>Change Password</div>
   
   
   <form onSubmit={handlsubmit} className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>

   <div className="mb-4">
    {/* {message?.length>0 ?<span className='text-red-500 p-1'>{message}</span>:""} */}
     <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Password">
     Current Password
     </label>
     <input onChange={(e)=>setCurrentPass(e.target.value)} value={currentPass} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Current Password" />
   </div>
   {status==400?<p className={`text-center text-gray-200 font-semibold ${status==400?"bg-red-600":""} p-2 my-2 rounded-sm`}>{message?.message}</p>:""} 
    {status==200?<p className={`text-center text-gray-200 font-semibold ${status==200?"bg-green-700":""} p-2 my-2 rounded-sm`}>{message?.message}</p>:""} 
   <div className="mb-4">
     <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
     New Password
     </label>
     <input onChange={(e)=>setPassword(e.target.value)} value={password} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="New Password" />
   </div>
   
  {loading?<p className='text-center text-gray-700 font-semibold bg-blue-300 p-2 my-2 rounded-sm'>Please Wait...</p>:""}
   <div class="flex items-center justify-between">
     <button class="bg-teal-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
       Update Password
     </button>
     <Link to="/update-user-profile" class="inline-block align-baseline font-bold text-md text-blue-500 hover:text-blue-800" href="#">
       Back
     </Link>
   </div> 
         
 

   </form>
   </div>
   
    
    
    </>
  )
}

export default ChangePass