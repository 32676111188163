import React from 'react'

const GalleryDetail = () => {
  return (
    <div className='grid grid-cols-3 gap-1'>
            <img src="/donate/old.jpg" alt="img" />
        <img src="./donate/1.jpg" alt="img" />
        <img src="/donate/old.jpg" alt="img" />
        <img src="./donate/1.jpg" alt="img" />
        <img src="/donate/old.jpg" alt="img" />
        <img src="./donate/1.jpg" alt="img" />
        <img src="/donate/old.jpg" alt="img" />
        <img src="./donate/1.jpg" alt="img" />
    </div>
  )
}

export default GalleryDetail